.page {
	&-parallax {
		position: relative;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		background-size: cover;
		background-attachment: fixed;
		background-repeat: repeat-y;
		background-position: bottom center;
		&:after {
			content: '';
			position: absolute;
			background-color: rgba($dark, 0.5);
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}
	}
	&-title {
		position: relative;
		z-index: $zindex-fixed * 0.3;
		padding: $font-size-base * 4 0;
		font-size: $font-size-base * 1.8;
		font-weight: 700;
		@include media-breakpoint-down(sm) {
			padding: $font-size-base * 3 0;
		}
	}
}

.parallax-cover {
	background-size: cover;
	// background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center center;
	max-height: 500px;
	overflow: hidden;
}
