.cards {
	display: block;
	&-checkbox {
		margin: auto $font-size-base * 0.3;
		.btn {
			padding-left: $font-size-base * 1.5;
			padding-right: $font-size-base * 1.5;
		}
	}
	.figure {
		border-radius: $border-radius-lg * 2;
		margin-bottom: $font-size-base * 0.8;
		overflow: hidden;
		img {
			display: block;
			width: 100%;
		}
	}
	&.with-border {
		border: 1px solid rgba($border-colors, 0.5);
		margin: $font-size-base * 0.7 auto;
		border-radius: $border-radius-lg * 4;
		padding: $font-size-base * 0.8;
		&:hover,
		.active {
			border-color: $primary;
			.card-text {
				color: $primary;
			}
		}
	}
	&.figure-border {
		.banner-svg {
			border: 1px solid rgba($border-colors, 0.5);
			&:hover,
			.active {
				border-color: $primary;
				.card-text {
					color: $primary;
				}
			}
		}
	}
	&-lg-content {
		// margin: $font-size-base * 2 auto;
		&.with-margin {
			@include media-breakpoint-down(sm) {
				margin-bottom: $font-size-base * 3;
			}
		}

		.title {
			position: relative;
			font-size: $font-size-base * 2.3;
			margin-bottom: $font-size-base * 0.9;
			@include media-breakpoint-down(sm) {
				font-size: $font-size-base * 1.8;
				margin-bottom: $font-size-base * 0.4;
			}
		}
		.label {
			position: relative;
			font-family: $font-red-hat-display;
			font-size: $font-size-base * 0.9;
			margin-bottom: $font-size-base * 0.2;
			// @include media-breakpoint-down(sm) {
			// 	font-size: $font-size-base * 1.8;
			// 	margin-bottom: $font-size-base * 0.4;
			// }
		}
		.sub-label {
			position: relative;
			font-size: $font-size-base * 1.7;
			margin-bottom: $font-size-base;
			@include media-breakpoint-down(sm) {
				font-size: $font-size-base * 1.3;
			}
		}
		.display {
			font-family: $font-fira-sans;
			font-size: $font-size-base * 1.3;
			margin: 0;
			&-small {
				font-size: $font-size-base * 1.1;
			}
			@include media-breakpoint-down(sm) {
				font-size: $font-size-base;
			}
		}
		.sub-title {
			position: relative;
			font-size: $font-size-base * 2;
			margin: 0;
			// margin-bottom: $font-size-base * 0.2;
		}
		.sub-display {
			font-family: $font-fira-sans;
			font-size: $font-size-base * 0.95;
			margin: 0;
		}
		.content {
			&-info {
				margin-top: $font-size-base * 2;
			}
		}
	}
	&-content {
		margin: $font-size-base * 2 auto;
		.title {
			position: relative;
			margin-bottom: $font-size-base * 1.5;
			padding-bottom: $font-size-base * 0.9;
			&:after {
				position: absolute;
				content: '';
				background-color: $danger;
				height: 4px;
				width: $font-size-base * 6;
				left: 0;
				bottom: 0;
			}
		}
		.embed {
			display: block;
			width: 100%;
		}
		.content-text {
			ul,
			ol {
				margin: 0;
				padding: 0;
				padding-left: $font-size-base;
				li {
					margin-bottom: $font-size-base * 0.4;
					ol,
					ul {
						margin-top: $font-size-base * 0.4;
						li {
							margin-bottom: $font-size-base * 0.2;
						}
					}
				}
			}
			.display {
				font-size: $font-size-base * 1.5;
				font-weight: 300;
				@include media-breakpoint-down(sm) {
					font-size: $font-size-base * 1.1;
				}
			}
		}
	}
	&.with-icons {
		display: flex;
		margin: $font-size-base * 0.7 auto;
		overflow: hidden;
		position: relative;
		border-radius: $border-radius-lg * 4;
		// padding: $font-size-base * 0.2;
		.figure {
			margin: 0;
			padding: 0;
		}
		.banner-svg {
			width: 100%;
			display: block;
			border-radius: $border-radius-lg * 4;
		}
		&:hover,
		.active {
			border-color: $primary;
			.card-text {
				color: $primary;
			}
		}
	}
	&-figure {
		position: relative;
		margin-bottom: $font-size-base * 1.4;
		.banner-svg {
			width: 100%;
			display: block;
			border-radius: $border-radius-lg * 4;
		}
		.text-img {
			position: absolute;
			bottom: $font-size-base;
			left: $font-poppins;
			color: $white;
			background: $dark;
			font-size: $font-size-base * 1.2;
			font-weight: 600;
			font-family: $font-poppins;
			padding: $font-size-base * 0.2 $font-size-base;
			z-index: 2;
			display: block;
		}
	}
	&-blockquote {
		padding-top: $font-size-base * 4;
		.title {
			&-content {
				font-family: $font-poppins;
				font-size: $font-size-base * 1.6;
				font-weight: 300;
				margin-bottom: $font-size-base * 3;
			}
			&-stars {
				margin-bottom: $font-size-base * 2;
				font-size: $font-size-base * 1.8;
				color: $orange;
			}
			@include media-breakpoint-down(sm) {
				&-content {
					font-size: $font-size-base * 1.1;
					margin-bottom: $font-size-base * 1.5;
				}
				&-stars {
					font-size: $font-size-base * 1.3;
					margin-bottom: $font-size-base;
				}
			}
		}
	}
	&-mobile {
		&-view {
			position: absolute;
			@include media-breakpoint-down(sm) {
				position: relative;
			}
			z-index: 2;
			bottom: 0;
			width: 100%;
			max-width: 340px;
			// padding-top: $font-size-base * 3;
			// overflow: hidden;
			&-inner {
				background-color: $gray-900;
				border-top-left-radius: $font-size-base * 3;
				border-top-right-radius: $font-size-base * 3;
				padding: $font-size-base * 0.5;
				padding-bottom: 0;
			}

			&-wrapper {
				.backgrounds {
					position: absolute;
					@include media-breakpoint-down(sm) {
						display: none;
					}
					z-index: -3;
					&.dots {
						top: 40%;
						left: -32%;
					}
					&.yellow {
						top: 60%;
						right: -20%;
					}
				}
			}
			margin: 0 auto;

			.swiper-slide {
				border-top-left-radius: $font-size-base * 2.6;
				border-top-right-radius: $font-size-base * 2.6;
				overflow: hidden;
			}
			&-img {
				display: block;
				width: 100%;
				overflow: hidden;
				img,
				.svg {
					display: block;
					width: 100%;
				}
			}
			&:after {
				content: '';
				width: $font-size-base * 0.35;
				height: $font-size-base * 0.35;
				// background-color: $white;
				background-color: $gray-900;
				position: absolute;
				border-radius: $font-size-base;
				z-index: 2;
				top: 20px;
				left: 35%;
			}
			&:before {
				content: '';
				border-radius: 10px;
				width: $font-size-base * 4;
				height: $font-size-base * 0.35;
				// background-color: $white;
				background-color: $gray-900;
				position: absolute;
				z-index: 2;
				top: 20px;
				left: 40%;
			}
		}
		&-center {
			position: relative;
			// position: absolute;
			@include media-breakpoint-down(sm) {
				margin: $font-size-base * 2.2 0;
			}

			z-index: 2;
			bottom: 0;
			width: 100%;
			max-width: 260px;
			margin: 0 auto;
			// padding-top: $font-size-base * 3;
			// overflow: hidden;
			&-inner {
				background-color: darken($primary, 20%);
				border-radius: $font-size-base * 2.6;
				padding: $font-size-base * 0.5;
			}
			.mobile-slider-school {
				padding-top: 30px;
				background-color: $white;
				border-radius: $font-size-base * 2.2;
				overflow: hidden;
			}

			&-wrapper {
				.backgrounds {
					position: absolute;
					@include media-breakpoint-down(sm) {
						display: none;
					}
					z-index: -3;
					&.dots {
						top: 40%;
						left: -32%;
					}
					&.yellow {
						top: 60%;
						right: -20%;
					}
				}
			}
			margin: 0 auto;

			&-img {
				display: block;
				width: 100%;
				overflow: hidden;
				img,
				.svg {
					display: block;
					width: 100%;
				}
			}
			&:after {
				content: '';
				width: $font-size-base * 0.35;
				height: $font-size-base * 0.35;
				// background-color: $white;
				background-color: $gray-900;
				position: absolute;
				border-radius: $font-size-base;
				z-index: 2;
				top: 20px;
				left: 35%;
			}
			&:before {
				content: '';
				border-radius: 10px;
				width: $font-size-base * 4;
				height: $font-size-base * 0.35;
				// background-color: $white;
				background-color: $gray-900;
				position: absolute;
				z-index: 2;
				top: 20px;
				left: 40%;
			}
		}
	}
}
