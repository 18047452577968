//
// booking card form
// --------------------------------------------------
html {
	scroll-behavior: smooth;
}
body {
	// padding-top: $navbar-brand-height * 2.45;
	// padding-bottom: $navbar-brand-height * 2.3;
	&.full-pages {
		padding: 0;
	}
}

a {
	text-decoration: $link-decoration;
}

.siteNavbar,
.section-navbar {
	z-index: $zindex-fixed;
	//padding: 10px 0;
	padding: 0;
	margin: 0;
	font-family: $font-fira-sans;

	&.default {
		// display: none;
		position: fixed;
		top: -300px;

		width: 100%;
		@include transition(top $offcanvas-transition-duration ease-in-out);
		&.nav-up {
			top: -300px;
			@include transition(top $offcanvas-transition-duration ease-in-out);
		}
		&.navbar-show {
			top: 0;
		}
	}

	// background-color: $brand-white;

	.icons-caret {
		font-size: 110%;
	}
	.menu-end {
		margin-left: $font-size-base;
		.btn {
			padding-top: $font-size-base * 0.5;
			padding-bottom: $font-size-base * 0.5;
			padding-left: $font-size-base * 1.2;
			padding-right: $font-size-base * 1.2;
			font-weight: 600;
			font-family: $font-poppins;
			font-size: $font-size-base * 0.85;
			border-radius: $border-radius-lg * 5;
		}
	}

	.navbar {
		border: 0;
		margin: 0;
		// padding: 0;
		@include media-breakpoint-down(sm) {
			&.no-padding {
				padding: 0;
			}
		}

		.btn-navbar {
			margin-left: $font-size-base * 2;
		}

		// padding: $padding-base-vertical 0;
		border-radius: 0;
		background-color: $white;
		// border-bottom: 2px solid lighten($default-border, 40%);
		// box-shadow: 0 1px 7px 2px rgba($gray-500, 0.3);

		.navbar-brand {
			margin-left: $padding-base-vertical * 0.5;
			@include media-breakpoint-down(sm) {
				margin-left: 0;
				margin-right: $font-size-base * 0.4;
			}
			img {
				display: block;
				height: 100%;
			}

			&.is-img {
				// padding: $navbar-brand-height * 0.5 0;
				padding: $navbar-brand-height * 0.5 0;
				.svg {
					height: $navbar-brand-height;
					@include media-breakpoint-down(sm) {
						height: $navbar-brand-height * 0.9;
					}
				}
				img {
					display: block;
					height: $navbar-brand-height * 1.05;
					@include media-breakpoint-down(sm) {
						height: $navbar-brand-height * 1.8;
					}
				}
			}
		}

		.navbar-toggler {
			border-color: rgba($border-colors, 0.5);
			padding: $font-size-base * 0.5;
			// padding-top: $font-size-base * 0.2;
			border-width: 2px;
			.svg {
				// height: $navbar-brand-height * 3;
				@include media-breakpoint-down(sm) {
					height: $navbar-brand-height * 0.9;
				}
			}
		}
		// .dropdown-menu > li > a {
		// 	padding: 8px 20px;

		// 	&:hover {
		// 		text-decoration: none;
		// 		color: $dropdown-link-hover-color;
		// 		background-color: $dropdown-link-hover-bg;
		// 	}
		// }
	}
}

.mobile-menu-list {
	padding: 0 $font-size-base * 0.8;
}
