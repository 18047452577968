@use 'sass:math';

$borderWidth: $font-size-base * .5;
$animationTime: 0.5s;
$size: 140px;
$howManySteps: 100; //this needs to be even.

.card-progress {
	width: $size;
	height: $size;
	line-height: $size;
	background: none;
	margin: 0 auto;
	box-shadow: none;
	position: relative;

	&:after {
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: $borderWidth solid $light-blue-color;
		position: absolute;
		top: 0;
		left: 0;
	}

	> span {
		width: 50%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		top: 0;
		z-index: 1;
	}

	.card-progress-left {
		left: 0;
	}

	.card-progress-bar {
		width: 100%;
		height: 100%;
		background: none;
		border-width: $borderWidth;
		border-style: solid;
		position: absolute;
		top: 0;
		border-color: $secondary;
	}

	.card-progress-left .card-progress-bar {
		left: 100%;
		border-top-right-radius: ($size * 0.5);
		border-bottom-right-radius: ($size * 0.5);
		border-left: 0;
		-webkit-transform-origin: center left;
		transform-origin: center left;
		//animation: loading-2 1.5s linear forwards 1.8s;
	}

	.card-progress-right {
		right: 0;

		.card-progress-bar {
			left: -100%;
			border-top-left-radius: ($size * 0.5);
			border-bottom-left-radius: ($size * 0.5);
			border-right: 0;
			-webkit-transform-origin: center right;
			transform-origin: center right;
			//animation: loading-1 1.8s linear forwards;
		}
	}

	.card-progress-value {
		display: flex;
		border-radius: 50%;
		text-align: center;
		color: #151313;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		line-height: $font-size-base * 0.7;
		font-family: $font-fira-sans;
		.text {
			font-size: $font-size-base * 3;
			line-height: $font-size-base * 2.5;
		}
		.mini{
      font-size: $font-size-base*1.5;
    }
		.small {
			display: block;
			font-size: $font-size-base;
		}
	}
}

/* This for loop creates the necessary css animation names 
Due to the split circle of card-progress-left and card-progress right, we must use the animations on each side. 
*/
@for $i from 1 through $howManySteps {
	$stepName: ($i * math.div(100, $howManySteps));

	//animation only the left side if below 50%
	@if $i <=($howManySteps * 0.5) {
		.card-progress[data-percentage='#{$stepName}'] {
			.card-progress-right .card-progress-bar {
				animation: loading-#{$i} $animationTime linear forwards;
			}

			.card-progress-left .card-progress-bar {
				animation: 0;
			}
		}
	}

	//animation only the right side if above 50%
	@if $i>($howManySteps * 0.5) {
		.card-progress[data-percentage='#{$stepName}'] {
			.card-progress-right .card-progress-bar {
				//set the animation to longest animation
				animation: loading-#{($howManySteps * 0.5)} $animationTime forwards;
			}

			.card-progress-left .card-progress-bar {
				animation: loading-#{$i -
					($howManySteps * 0.5)}
					$animationTime
					linear
					forwards
					$animationTime;
			}
		}
	}
}

//animation
@for $i from 1 through ($howManySteps * 0.5) {
	$degrees: math.div(180, ($howManySteps * 0.5));
	$degrees: ($degrees * $i);

	@keyframes loading-#{$i} {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate($degrees);
			transform: rotate(#{$degrees}deg);
		}
	}
}

.gc_market {
	@media (max-width: 767px) {
		.row {
			flex-wrap: nowrap;
			overflow-y: auto;
		}
	}

	&--name {
		font-size: 15px;
		color: #262424;
		font-family: $font-poppins;
		text-align: center;
		margin-top: 10px;
	}

	&_list {
		display: flex;
		align-items: center;
		justify-content: center;

		&_each {
			display: flex;
			align-items: center;
			font-family: $font-poppins;
			margin: 0 20px;

			span {
				background: var(--data-bg);
				width: 20px;
				height: 20px;
				border-radius: 50%;
				display: inline-flex;
				margin-right: 10px;
			}
		}
	}
}
