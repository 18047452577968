//
// Base styles
//
.card-slider {
	&-prev,
	&-next {
		padding: $font-size-base * 0.2 $font-size-base * 0.5;
		background-color: rgba($border-colors, 0.25);
		border-radius: $border-radius-lg;
		&:hover {
			background-color: $primary;
			svg,
			.svg {
				path {
					fill: $white;
				}
				fill: $white;
			}
		}
		&.swiper-button-disabled {
			cursor: auto;
			pointer-events: none;
			@include opacity(0.7);
		}
	}

	&-next {
		margin-left: $font-size-base * 0.5;
	}
}

.card {
	&-marquee {
		.activity-nav {
			margin-top: $font-size-base;
		}
		.swiper-slide {
			.marquee-text {
				padding: $font-size-base * 0.2 $font-size-base * 3;
			}
		}
	}
}


 