//
// Footer
// --------------------------------------------------
@use 'sass:math';

.move-top {
	position: fixed;
	right: 20px;
	bottom: 15px;
	padding: 0.25rem 0;
	width: $input-height-sm;
	height: $input-height-sm;
	// line-height: math.div($input-height, 1.3);
	text-align: center;
	font-size: 120%;
	border-radius: 50%;
	z-index: 999;
	background: $white;
	color: $theme-color;
	border: 1px solid $theme-color;

	&:hover {
		background-color: $theme-color;
		color: $white;
		border-color: darken($theme-color, 10%);
	}
}

.page-footer {
	// background-color: $gray-100;
	background-color: darken($primary, 20%);
	// border-top: $font-size-base * 0.1 solid $gray-200;
	padding-top: $font-size-base * 2;
	&.no-padding{
		padding: 0;
	}
	ul {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
		}
	}
}
.footer-icons {
	position: relative;
	&:after {
		content: '';
		border-radius: $border-radius-sm;
		background-color: transparent;
		position: absolute;
		top: 1px;
		left: 0;
		right: 0;
		z-index: 2;
		height: $font-size-base * 0.2;
	}
	a {
		display: block;
		img {
			display: block;
			margin: 0 auto;
			width: $font-size-base * 3.54;
			height: $font-size-base * 3.5;
		}
		svg,
		.svg {
			display: block;
			margin: 0 auto;
			width: $font-size-base * 3.54;
			height: $font-size-base * 3.5;
		}
	}
}

.footer {
	&-list {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
			&:hover {
				svg,
				.svg {
					path {
						fill: $primary;
					}
					fill: $primary;
				}
			}
			&.active {
				.footer-icons {
					&:after {
						background-color: $primary;
					}
					svg,
					.svg {
						path {
							fill: $primary;
						}
						fill: $primary;
					}
				}
			}
		}
	}
	&-app {
		margin: $font-size-base * 1.2 auto;
		.header {
			margin: 0;
			padding: 0;
			margin-right: $font-size-base;
			text-transform: uppercase;
			font-weight: 600;
			color: $white;
			// @include opacity(0.7);
		}
		.svg-link {
			display: inline-block;
			.svg {
				height: $navbar-brand-height * 1.5;
				@include media-breakpoint-down(sm) {
					height: $navbar-brand-height * 1.5;
				}
			}
		}
	}
	&-nav {
		margin: $font-size-base * 1.2 auto;
		&.no-margin {
			margin: $font-size-base * 0.2 auto;
		}
		li {
			padding: 0;
			margin: $font-size-base * 0.5 0;
			border-right: 1px solid $default-border;
			line-height: 1;
			@include media-breakpoint-down(sm) {
				// padding: 0 $font-size-base * 0.3;
				// margin: $font-size-base * 0.2 auto;
				font-size: $font-size-base * 0.8;
			}
			&:last-child {
				border: none;
			}
			a {
				line-height: 1;
				padding: 0 $font-size-base * 0.8;
				@include opacity(0.8);
				// display: inline-block;
				font-weight: 500;
				// color: $body-color;
				color: $white;
				&:hover,
				&:focus {
					@include opacity(1);
					color: $info;
				}
			}
		}
	}
	&-soical {
		margin-bottom: $font-size-base * 1.2;
		.text {
			display: none;
		}
		a {
			display: block;
			font-size: $font-size-base * 1.2;
			width: $font-size-base * 3;
			height: $font-size-base * 3;
			color: $white;
			@include opacity(0.7);
		}
	}
	&-address {
		.header {
			margin: 0;
			padding: 0;
		}
	}
	&-copyright {
		&-inner {
			border-top: 1px solid rgba($white, 0.05);
			padding: $font-size-base * 0.8 0;
		}
		margin-top: $font-size-base * 1.2;
		font-size: $font-size-base * 0.9;

		li {
			padding: 0 $font-size-base * 1.5;
			margin: auto $font-size-base * 0.2;
			border-right: 1px solid $default-border;
			line-height: 1;
			color: $white;
			@include opacity(0.8);
			// &:hover,
			// &:focus {
			// 	@include opacity(1);
			// }
			&:last-child {
				border: none;
			}
			a {
				line-height: 1;
				display: inline-block;
				font-weight: 500;
				color: $white;
				&:hover,
				&:focus {
					color: $info;
					@include opacity(1);
				}
			}
		}
		@include media-breakpoint-down(sm) {
			font-size: $font-size-base * 0.7;
			li {
				margin: $font-size-base * 0.2 auto;
				border: none;
			}
		}
	}
}
