.card-offcanvas-menu {
	// display: block;
	display: flex;
	align-items: center;
	// font-family: $font-poppins;
	// line-height: $font-size-base * 1.1;
	padding: $font-size-base * 0.65 $font-size-base * 0.8;
	padding-bottom: $font-size-base * 0.7;
	text-align: center;
	border: 1px solid transparent;
	margin: $font-size-base * 0.1 auto;
	border-radius: $border-radius-sm;

	.card-icon {
		color: $dark;
		// display: inline-block;
		max-width: $font-size-base * 3;
		padding-right: $font-size-base * 1.2;
	}
	.image {
		display: inline-block;
		width: $font-size-base * 1.65;
		height: $font-size-base * 1.65;
		position: relative;
		z-index: 3;
		img {
			display: block;
			width: 100%;
		}
		// svg {
		// 	path {
		// 		@include fill-social();
		// 	}
		// 	@include fill-social();
		// }
	}

	.card-text {
		// display: inline-block;
		color: $dark;
		vertical-align: top;
		font-size: $font-size-base;
		// margin-top: $font-size-base * 0.8;
		font-weight: 500;
		padding-top: $font-size-base * 0.1;
	}
	&:hover,
	&.active {
		border-color: $primary;
		.card-text {
			color: $primary;
		}
		.image {
			svg {
				path {
					fill: $secondary;
				}
				fill: $secondary;
			}
		}
	}
}

.mobile-menu-list {
	li {
		.card-offcanvas-menu {
		}
		&.active {
			.card-offcanvas-menu {
				border-color: $primary;
				.card-text {
					color: $primary;
				}
				.image {
					svg {
						path {
							fill: $secondary;
						}
						fill: $secondary;
					}
				}
			}
		}
	}
}

.mobile-menu-ul {
	position: relative;
	padding: $modal-inner-padding * 0.5;
	margin-top: $font-size-base * 2;
	&:after {
		content: '';
		border-radius: $border-radius-sm;
		background-color: rgba($border-colors, 0.5);
		position: absolute;
		top: 0;
		left: $font-size-base * 1.5;
		right: $font-size-base * 1.5;
		z-index: 2;
		height: $font-size-base * 0.2;
	}
	.heading {
		padding-top: $font-size-base;
		padding-left: $font-size-base;
	}
}

.offcanvas-header {
	.with-logo {
	}
	.logo {
		&.svg {
			height: $navbar-brand-height;
			@include media-breakpoint-down(sm) {
				height: $navbar-brand-height;
			}
		}
	}

	&.with-bg {
		align-items: flex-start;
		padding-right: $font-size-base;
		padding-top: $font-size-base;
		padding-bottom: $font-size-base * 1.2;
		.offcanvas-title {
			padding: $font-size-base * 1.5 0;
			padding-top: $font-size-base * 2;
		}
		position: relative;
		&:after {
			content: '';
			border-radius: $border-radius-sm;
			background-color: rgba($border-colors, 0.5);
			position: absolute;
			bottom: 0;
			left: $font-size-base * 2;
			right: $font-size-base * 2;
			z-index: 2;
			height: $font-size-base * 0.2;
		}
		// padding-left: $font-size-base;
	}
}
