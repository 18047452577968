.section {
	&-pop {
		&-card {
			padding: $font-size-base * 1.2 $font-size-base * 2.8;
			padding-bottom: $font-size-base * 3;
		}
		&-fcard {
			margin-top: $font-size-base * 2;
			.text-fogot {
				margin-bottom: $font-size-base * 2;
				font-size: $font-size-base * 0.95;
			}
		}
		&-footer {
			padding-top: $font-size-base * 2;
		}
	}
	&-header-terms {
		position: relative;
	}
	&-avatar {
		&.combo {
			border: 1px solid rgba($border-colors, 0.5);
			padding: $font-size-base * 0.6 $font-size-base * 0.8;
			border-radius: $input-border-radius-lg;
			margin-bottom: $font-size-base * 2;
			// &:hover,
			// .active {
			// 	border-color: $primary;
			// 	.card-text {
			// 		color: $primary;
			// 	}
			// }
			.avatar-card {
				margin-bottom: $font-size-base;
				padding-bottom: $font-size-base;
				border-bottom: 1px solid rgba($border-colors, 0.5);
				&:last-child {
					// margin: 0;
					border: none;
					padding-bottom: 0;
				}
			}
		}
	}
	&-login {
		&-wrapper {
			position: relative;
			z-index: 2;
			width: 520px; 
			margin: 0 auto;
		}
		&-card {
			border-radius: $font-size-base * 1.4;
			background-color: rgba($white, 0.1);
			-webkit-backdrop-filter: blur(8px);
			backdrop-filter: blur(8px);
			padding: $font-size-base * 2.5 $font-size-base * 4; 
			border: 1px solid rgba($white, 0.2);
			margin-bottom: $font-size-base * 2;
			.brand {
				&-title {
					font-size: $font-size-base * 3.6;
					color: $white;
					line-height: 0.9;
					font-weight: 900; 
					font-family: $font-poppins;
					// text-transform: u;
				}
				&-img {
					max-width: $font-size-base * 5;
					background-color: $white;
					padding: $font-size-base;
					border-radius: $font-size-base * 4;
					margin-right: $font-size-base * 2;
					svg,
					.svg {
						display: block;
						margin: 0 auto;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		&-fcard {
			margin-top: $font-size-base * 4;
			.text-fogot {
				margin-bottom: $font-size-base * 2;
				font-size: $font-size-base * 0.95;
			}
		}
		&-footer {
			padding-top: $font-size-base;
		}
	}
	&-contact {
		.title {
			font-size: $font-size-base * 1.8;
			// margin: 0;
		}
		&.mini-card {
			padding: $font-size-base * 1.2 $font-size-base * 1.8;
			border-radius: $input-border-radius-lg * 3;
			margin: $font-size-base * 2 auto;
			border: 1px solid rgba($primary, 0.5);
			min-height: 280px;
			// .title {
			// 	font-size: $font-size-base * 1.8;
			// 	// margin: 0;
			// }
			.time {
				@include text-social();
			}
		}
		.title {
		}

		.contact-content {
			.time {
			}

			.purpose {
				margin-top: $font-size-base * 1.5;
				p {
					margin: 0;
				}
			}
		}
		.avatar-card {
			margin: $font-size-base * 1.5 auto;
		}
	}
	&-module {
		// padding: $font-size-base * 3 0;
		padding-bottom: 0;
		&-features {
			padding: $font-size-base * 3 0;
			@include media-breakpoint-down(sm) {
				padding: $font-size-base 0;
			}
			// background-color: $gray-100;
		}
	}
	&-blog {
		background-color: $gray-100;
		padding: $font-size-base * 4.5 0;
		@include media-breakpoint-down(sm) {
			padding: $font-size-base * 1.5 0;
		}
		.cards-lg-content {
			margin-bottom: $font-size-base * 3;
		}
		// &-features {
		// 	padding: $font-size-base * 3 0;
		// 	background-color: $gray-100;
		// }
	}
	&-blockquote {
		padding: $font-size-base * 4.5 0;
		@include media-breakpoint-down(sm) {
			padding: $font-size-base * 1.5 0;
		}
		// background-color: $gray-100;
		// &-features {
		// 	padding: $font-size-base * 3 0;
		// 	background-color: $gray-100;
		// }
	}
	&-contact-us {
		overflow: hidden;
		position: relative;
		background-color: lighten($primary, 52%);
		padding: $font-size-base * 6 0;
		@include media-breakpoint-down(sm) {
			padding: $font-size-base * 2 0;
		}

		.purpose {
			margin: $font-size-base * 1.5 0;
		}
		.avatar-card {
			margin-top: $font-size-base;
		}
		.backgrounds {
			position: absolute;
			@include media-breakpoint-down(sm) {
				display: none;
			}
			// z-index: 1;
			&.dots {
				top: 15%;
				left: -5%;
			}
			&.white {
				bottom: -12%;
				left: 35%;
				@include opacity(0.35);
			}
			&.yellow {
				// width: 100px;
				bottom: 40%;
				right: -7%;
				@include opacity(0.3);
				// opeci
			}
		}
		// background-color: $gray-100;
		// &-features {
		// 	padding: $font-size-base * 3 0;
		// 	background-color: $gray-100;
		// }
	}
}
