/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
// Core variables and mixins

@import 'bootstrap/functions';
@import 'variables';
@import 'bootstrap/mixins';
@import 'bootstrap/utilities';
@import 'style/mixins';

// Core CSS
@import 'style/buttons';
@import 'style/checkbox'; 
@import 'style/article'; 
@import 'style/blog'; 
@import 'style/radio-buttons';
@import 'style/section'; 
@import 'style/page'; 
@import 'style/page-parallax';
@import 'style/modal';
@import 'style/table-pagination';
@import 'style/page-signup'; 
@import 'style/navbar'; 
@import 'style/soical-link';
@import 'style/form-control';
@import 'style/footer';
@import 'style/icons-list';
@import 'style/avatar-card';
@import 'style/card-offcanvas-menu';
@import 'style/text-card';
@import 'style/activity';
@import 'style/progress';
@import 'style/card-slider';
@import 'style/cards';