/* Radio Buttons
   ========================================================================== */

.btn {
	&-radio-box {
		position: relative;
		z-index: 20;
		@include transition(0.3s ease-out);
		padding: $font-size-base * 0.65 $font-size-base * 0.8;
		padding-bottom: $font-size-base * 0.7;
		// text-align: center;
		margin: $font-size-base * 0.5 auto;
		border-radius: $border-radius-lg;
		border: 1px solid rgba($border-colors, 0.5);
		input {
			cursor: pointer;
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 10; 
			background-color: transparent;
			// visibility: hidden;
			@include opacity(0);
		}
		&:hover {
			border-color: rgba($primary, 0.3);
			background-color: rgba($border-colors, 0.15);
		}
		&.active {
			border-color: $primary;
			background-color: rgba($border-colors, 0.15);
		}
	}
	// &-radio-box {
	// }
}

.btn-check-flex {
	.btn-input-radio {
		&.active {
			border-color: $primary;
			background-color: rgba($border-colors, 0.15);
		}
	}
}
