/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.btn {
  transition: 0.15s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn.round {
  border-radius: 50rem;
}
.btn.prefix {
  position: relative;
  padding-left: 42px;
}
.btn.prefix .fa,
.btn.prefix .fas,
.btn.prefix .far,
.btn.prefix .fal,
.btn.prefix .fab,
.btn.prefix .ich {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  left: 1.4em;
}
.btn:hover {
  color: #fff;
}
.btn.hoverable:hover, .btn.hoverable:focus, .btn.hoverable.focus {
  text-decoration: none;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
.btn.btn-minwidth {
  width: 20px;
  margin: 0 auto;
  display: block;
}
.btn.btn-min-width {
  min-width: 200px;
}
.btn.confirm {
  min-width: 160px;
}
.btn.btn-back {
  min-width: 100px;
}
.btn.dropdown-toggle {
  outline: 0;
  outline-offset: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
.bootstrap-select .dropdown-toggle {
  outline: 0;
  outline-offset: 0;
}
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:focus {
  outline: 0;
  outline-offset: 0;
}
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle.btn-xs,
.bootstrap-select .dropdown-toggle.btn-xs {
  padding-right: 0.5rem;
}

.article-particles {
  position: relative;
  background-color: #01193d;
}
.article-particles canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.article-logo {
  position: relative;
  max-width: 480px;
  margin: 0 auto;
}
@media (max-width: 575.98px) {
  .article-logo {
    max-width: initial;
  }
}
.article-logo .brand-img {
  max-width: 40%;
  margin: 0 auto;
}
@media (max-width: 575.98px) {
  .article-logo .brand-img {
    max-width: 40%;
  }
}
.article-logo .brand-title {
  font-size: 1.3rem;
  margin-top: 3.5rem;
}
@media (max-width: 575.98px) {
  .article-logo .brand-title {
    font-size: 1.2rem;
  }
}
.article-logo .brand-apps {
  margin: 1.3rem auto;
}
.article-logo .permalink {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}
@media (max-width: 575.98px) {
  .article-benifits.parallax-cover {
    background-size: contain;
  }
}
.article-mobile-vertical {
  background-color: #4646c7;
  margin-top: 4rem;
  margin-bottom: 3rem;
  border-radius: 1rem;
}
.article-mobile-vertical .cards-lg-content {
  padding: 4rem 0;
}
@media (max-width: 575.98px) {
  .article-mobile-vertical {
    margin-top: 0;
    margin-bottom: 0;
  }
  .article-mobile-vertical .cards-lg-content {
    padding: 2rem 0;
  }
}
.article-mobile-vertical-section {
  padding: 6rem 0;
  padding-top: 3.4rem;
}
@media (max-width: 575.98px) {
  .article-mobile-vertical-section {
    padding: 0;
  }
}
@media (max-width: 575.98px) {
  .article-mobile-vertical-full {
    padding: 2rem 0;
  }
}
.article-checkbox {
  padding: 0.875rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  background-color: #fff;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .article-checkbox {
    transition: none;
  }
}
.article-module {
  margin-top: 2rem;
}
.article-management {
  background-color: #f2f9fe;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 2rem;
  padding: 2rem 1.2rem;
  border-radius: 0.6rem;
}
@media (prefers-reduced-motion: reduce) {
  .article-management {
    transition: none;
  }
}
.article-management .icons-card {
  margin-bottom: 1.5rem;
}
@media (max-width: 575.98px) {
  .article-management {
    padding: 1rem;
    text-align: center;
  }
  .article-management .icons-card {
    margin: 0 auto;
    margin-bottom: 1.3rem;
  }
}
.article-management .title-meta {
  margin-bottom: 0.6rem;
}
.article-management .title-text {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}
.article-management .title-content {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
}
.article-management .title-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  height: 4rem;
  margin: 0;
}
@media (max-width: 575.98px) {
  .article-management .title-text {
    font-size: 0.8rem;
  }
  .article-management .title-content {
    font-size: 0.75rem;
  }
  .article-management .title-content p {
    height: 3.4rem;
  }
}
.article-blogs {
  border-bottom: 1px solid #ced4da;
  max-width: 540px;
  margin-bottom: 2.2rem;
  padding-bottom: 1.2rem;
}
.article-blogs .meta-tags {
  margin-bottom: 1.5rem;
}
.article-blogs .meta-content {
  font-size: 0.95rem;
  font-family: "Fira Sans", sans-serif;
}
.article-blockquote {
  position: relative;
  margin: 1.5rem auto;
  padding: 3rem 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 575.98px) {
  .article-blockquote {
    margin: 1rem auto;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
.article-blockquote:after {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 2rem;
  left: 8rem;
  right: 0;
  background-color: #dee2e6;
}
.article-blockquote:before {
  content: open-quote;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 40px;
  position: absolute;
  top: 0;
  left: 0;
  color: #2F2F9B;
  letter-spacing: -5px;
  font-size: 6rem;
  line-height: 6.3rem;
  text-align: center;
  box-shadow: 0 1px 7px 2px rgba(173, 181, 189, 0.3);
}

.app-link {
  position: relative;
  z-index: 10;
  margin: 0 0.25rem;
  display: flex;
}
.app-link img,
.app-link .svg {
  height: 2.6rem;
}

/* Radio Buttons
   ========================================================================== */
.btn-radio-box {
  position: relative;
  z-index: 20;
  transition: 0.3s ease-out;
  padding: 0.65rem 0.8rem;
  padding-bottom: 0.7rem;
  margin: 0.5rem auto;
  border-radius: 0.3rem;
  border: 1px solid rgba(187, 215, 246, 0.5);
}
@media (prefers-reduced-motion: reduce) {
  .btn-radio-box {
    transition: none;
  }
}
.btn-radio-box input {
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: transparent;
  filter: alpha(opacity=0);
  opacity: 0;
}
.btn-radio-box:hover {
  border-color: rgba(47, 47, 155, 0.3);
  background-color: rgba(187, 215, 246, 0.15);
}
.btn-radio-box.active {
  border-color: #2F2F9B;
  background-color: rgba(187, 215, 246, 0.15);
}

.btn-check-flex .btn-input-radio.active {
  border-color: #2F2F9B;
  background-color: rgba(187, 215, 246, 0.15);
}

.section-pop-card {
  padding: 1.2rem 2.8rem;
  padding-bottom: 3rem;
}
.section-pop-fcard {
  margin-top: 2rem;
}
.section-pop-fcard .text-fogot {
  margin-bottom: 2rem;
  font-size: 0.95rem;
}
.section-pop-footer {
  padding-top: 2rem;
}
.section-header-terms {
  position: relative;
}
.section-avatar.combo {
  border: 1px solid rgba(187, 215, 246, 0.5);
  padding: 0.6rem 0.8rem;
  border-radius: 0.3rem;
  margin-bottom: 2rem;
}
.section-avatar.combo .avatar-card {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(187, 215, 246, 0.5);
}
.section-avatar.combo .avatar-card:last-child {
  border: none;
  padding-bottom: 0;
}
.section-login-wrapper {
  position: relative;
  z-index: 2;
  width: 520px;
  margin: 0 auto;
}
.section-login-card {
  border-radius: 1.4rem;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  padding: 2.5rem 4rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 2rem;
}
.section-login-card .brand-title {
  font-size: 3.6rem;
  color: #fff;
  line-height: 0.9;
  font-weight: 900;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}
.section-login-card .brand-img {
  max-width: 5rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 4rem;
  margin-right: 2rem;
}
.section-login-card .brand-img svg,
.section-login-card .brand-img .svg {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.section-login-fcard {
  margin-top: 4rem;
}
.section-login-fcard .text-fogot {
  margin-bottom: 2rem;
  font-size: 0.95rem;
}
.section-login-footer {
  padding-top: 1rem;
}
.section-contact .title {
  font-size: 1.8rem;
}
.section-contact.mini-card {
  padding: 1.2rem 1.8rem;
  border-radius: 0.9rem;
  margin: 2rem auto;
  border: 1px solid rgba(47, 47, 155, 0.5);
  min-height: 280px;
}
.section-contact.mini-card .time {
  color: #6874a7;
}
.section-contact .contact-content .purpose {
  margin-top: 1.5rem;
}
.section-contact .contact-content .purpose p {
  margin: 0;
}
.section-contact .avatar-card {
  margin: 1.5rem auto;
}
.section-module {
  padding-bottom: 0;
}
.section-module-features {
  padding: 3rem 0;
}
@media (max-width: 575.98px) {
  .section-module-features {
    padding: 1rem 0;
  }
}
.section-blog {
  background-color: #f8f9fa;
  padding: 4.5rem 0;
}
@media (max-width: 575.98px) {
  .section-blog {
    padding: 1.5rem 0;
  }
}
.section-blog .cards-lg-content {
  margin-bottom: 3rem;
}
.section-blockquote {
  padding: 4.5rem 0;
}
@media (max-width: 575.98px) {
  .section-blockquote {
    padding: 1.5rem 0;
  }
}
.section-contact-us {
  overflow: hidden;
  position: relative;
  background-color: #dedef5;
  padding: 6rem 0;
}
@media (max-width: 575.98px) {
  .section-contact-us {
    padding: 2rem 0;
  }
}
.section-contact-us .purpose {
  margin: 1.5rem 0;
}
.section-contact-us .avatar-card {
  margin-top: 1rem;
}
.section-contact-us .backgrounds {
  position: absolute;
}
@media (max-width: 575.98px) {
  .section-contact-us .backgrounds {
    display: none;
  }
}
.section-contact-us .backgrounds.dots {
  top: 15%;
  left: -5%;
}
.section-contact-us .backgrounds.white {
  bottom: -12%;
  left: 35%;
  filter: alpha(opacity=35);
  opacity: 0.35;
}
.section-contact-us .backgrounds.yellow {
  bottom: 40%;
  right: -7%;
  filter: alpha(opacity=30);
  opacity: 0.3;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background-color: #dee2e6;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #2F2F9B;
  border-radius: 2px;
}

.no-pad {
  padding: 0px !important;
}

.border_radius_10 {
  border-radius: 10px;
}

.image-full img {
  width: 100%;
}
.image-full img.round {
  border-radius: 0.9rem;
}

.gap_10 {
  height: 10px;
}

.gap_20 {
  height: 20px;
}

.gap_30 {
  height: 30px;
}

.gap_40 {
  height: 40px;
}
@media (max-width: 768px) {
  .gap_40 {
    height: 30px;
  }
}

.gap_60 {
  height: 60px;
}
@media (max-width: 768px) {
  .gap_60 {
    height: 40px;
  }
}

.gap_80 {
  height: 80px;
}
@media (max-width: 768px) {
  .gap_80 {
    height: 50px;
  }
}

.gap_100 {
  height: 100px;
}
@media (max-width: 768px) {
  .gap_100 {
    height: 60px;
  }
}

.gap_120 {
  height: 120px;
}
@media (max-width: 768px) {
  .gap_120 {
    height: 100px;
  }
}

@media (max-width: 768px) {
  .gap_50--mob {
    height: 50px;
  }
}

@media (max-width: 768px) {
  .gap_20--mob {
    height: 20px;
  }
}

.overflow_hidden {
  overflow: hidden;
}

.btn_w200 {
  min-width: 200px;
}

.bold {
  font-weight: 600 !important;
}

.page-title.with-margin {
  margin-bottom: 3rem;
}
.page-title .title {
  font-size: 125%;
  margin-bottom: 1rem;
}
@media (max-width: 575.98px) {
  .page-title .title {
    margin: 0;
    font-size: 105%;
  }
}
.page-title .sub-title {
  font-size: 100%;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 500 !important;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}
.page-title .thankyou-file {
  margin-top: 3rem;
}
.page-title .thankyou-file .text {
  font-size: 80%;
  margin-left: 0.5rem;
}
@media (min-width: 576px) {
  .page-title .title {
    font-size: 200%;
  }
  .page-title .sub-title {
    font-size: 150%;
    margin-top: 2.2rem;
  }
}

.with-top-radius {
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem;
  position: relative;
  z-index: 4;
}

.section-padding {
  padding: 3rem 0;
}
@media (max-width: 575.98px) {
  .section-padding {
    padding: 1rem 0;
  }
}
.section-padding.with-hero {
  padding-top: 0;
  padding-bottom: 0;
}
.section-padding.with-blog {
  padding: 1rem 0.8rem;
  padding-bottom: 0;
}
.section-padding.with-single {
  padding: 1rem 0.9rem;
  padding-bottom: 0;
}
.section-padding.with-bg {
  padding: 2rem 0.8rem;
  padding-bottom: 0.5rem;
}
.section-padding.with-form {
  padding: 1.3rem 1.7rem;
  padding-bottom: 0.5rem;
}
.section-padding.with-login {
  padding: 2rem 0;
  padding-bottom: 0;
  z-index: 422;
}

.page-parallax {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: repeat-y;
  background-position: bottom center;
}
.page-parallax:after {
  content: "";
  position: absolute;
  background-color: rgba(33, 37, 41, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.page-title {
  position: relative;
  z-index: 309;
  padding: 4rem 0;
  font-size: 1.8rem;
  font-weight: 700;
}
@media (max-width: 575.98px) {
  .page-title {
    padding: 3rem 0;
  }
}

.parallax-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-height: 500px;
  overflow: hidden;
}

.modal-header-terms {
  position: relative;
  padding: 1.5rem;
  padding-right: 4rem;
}
.modal-header-terms .btn-close {
  padding: 1.5rem;
  right: 0;
  top: 0;
  z-index: 2;
  position: absolute;
}
.modal-header-terms .modal-title {
  font-size: 1.6rem;
}
.modal-header-terms .btn-close {
  font-size: 1.3rem;
}
.modal-header-terms .title {
  font-size: 0.8rem;
  font-weight: 600;
}

.modal-footer::after {
  display: block;
  clear: both;
  content: "";
}

.modal-dialog.modal-padding .modal-body {
  padding: 2rem 2.5rem;
  padding-bottom: 1rem;
}
.modal-dialog.modal-padding .modal-footer {
  padding: 2.5rem;
  padding-bottom: 2rem;
  padding-top: 0;
  border: 0;
}
.modal-dialog.modal-upload .modal-body {
  padding: 1rem 2.5rem;
  padding-bottom: 3rem;
}
.modal-dialog.modal-upload .modal-body.with-overflow {
  padding-right: 0;
}
.modal-dialog.modal-upload .modal-footer {
  padding: 2.5rem;
  padding-bottom: 2rem;
  padding-top: 0;
  border: 0;
}
.modal-dialog .section-plans {
  overflow-x: auto;
  padding-top: 1rem;
}
.modal-dialog .modal-plans-body {
  padding-left: 2.5rem;
  margin-bottom: 1rem;
}

.table-pagination {
  border: 1px solid #9c9ce1;
  padding: 0.1rem;
  margin: 1rem 0;
  border-radius: 0.3rem;
}
.table-pagination .pagination {
  margin: 0;
}

.table-symbols {
  font-size: 0.875rem;
  margin: 0;
}
.table-symbols > :not(:last-child) > :last-child > * {
  border-bottom-color: #dee2e6;
}
.table-symbols > tbody > tr:last-child td {
  border-color: transparent;
}
.table-symbols > thead > tr > th {
  border-color: #6161cf;
  background-color: #bbbbea;
}
.table-symbols .badge {
  font-size: 0.75rem;
}

.signup-wrapper {
  padding: 5rem 0;
}
@media (min-width: 576px) {
  .signup-wrapper {
    padding: 5rem 0;
  }
}

.signup-form.mini-card {
  max-width: initial;
}
.signup-form.large-card {
  max-width: initial;
}
.signup-form .form-info {
  padding: 1rem 0;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.signup-form .form-info .bold {
  margin: 0 10px;
}
.signup-form .form-info .disable {
  filter: alpha(opacity=50);
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
@media (min-width: 576px) {
  .signup-form.mini-card {
    max-width: 340px;
  }
  .signup-form.large-card {
    max-width: 390px;
  }
}
.signup-form .d-grid {
  margin-top: 2rem;
}

.list-otp-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.list-otp-input .text-control {
  width: 15%;
  height: 3.5rem;
  padding-left: 0;
  padding-right: 0;
  max-width: 50px;
  border-radius: 5px;
  text-align: center;
  font-size: 28px;
  line-height: 100%;
  color: #1e7ae0;
  border: #bcd5f7 1px solid;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
  outline: 0;
  outline-offset: 0;
}
.list-otp-input .text-control::-webkit-outer-spin-button, .list-otp-input .text-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body.full-pages {
  padding: 0;
}

a {
  text-decoration: none;
}

.siteNavbar,
.section-navbar {
  z-index: 1030;
  padding: 0;
  margin: 0;
  font-family: "Fira Sans", sans-serif;
}
.siteNavbar.default,
.section-navbar.default {
  position: fixed;
  top: -300px;
  width: 100%;
  transition: top 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .siteNavbar.default,
.section-navbar.default {
    transition: none;
  }
}
.siteNavbar.default.nav-up,
.section-navbar.default.nav-up {
  top: -300px;
  transition: top 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .siteNavbar.default.nav-up,
.section-navbar.default.nav-up {
    transition: none;
  }
}
.siteNavbar.default.navbar-show,
.section-navbar.default.navbar-show {
  top: 0;
}
.siteNavbar .icons-caret,
.section-navbar .icons-caret {
  font-size: 110%;
}
.siteNavbar .menu-end,
.section-navbar .menu-end {
  margin-left: 1rem;
}
.siteNavbar .menu-end .btn,
.section-navbar .menu-end .btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  font-weight: 600;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 0.85rem;
  border-radius: 1.5rem;
}
.siteNavbar .navbar,
.section-navbar .navbar {
  border: 0;
  margin: 0;
  border-radius: 0;
  background-color: #fff;
}
@media (max-width: 575.98px) {
  .siteNavbar .navbar.no-padding,
.section-navbar .navbar.no-padding {
    padding: 0;
  }
}
.siteNavbar .navbar .btn-navbar,
.section-navbar .navbar .btn-navbar {
  margin-left: 2rem;
}
.siteNavbar .navbar .navbar-brand,
.section-navbar .navbar .navbar-brand {
  margin-left: 0.25rem;
}
@media (max-width: 575.98px) {
  .siteNavbar .navbar .navbar-brand,
.section-navbar .navbar .navbar-brand {
    margin-left: 0;
    margin-right: 0.4rem;
  }
}
.siteNavbar .navbar .navbar-brand img,
.section-navbar .navbar .navbar-brand img {
  display: block;
  height: 100%;
}
.siteNavbar .navbar .navbar-brand.is-img,
.section-navbar .navbar .navbar-brand.is-img {
  padding: 0.75rem 0;
}
.siteNavbar .navbar .navbar-brand.is-img .svg,
.section-navbar .navbar .navbar-brand.is-img .svg {
  height: 1.5rem;
}
@media (max-width: 575.98px) {
  .siteNavbar .navbar .navbar-brand.is-img .svg,
.section-navbar .navbar .navbar-brand.is-img .svg {
    height: 1.35rem;
  }
}
.siteNavbar .navbar .navbar-brand.is-img img,
.section-navbar .navbar .navbar-brand.is-img img {
  display: block;
  height: 1.575rem;
}
@media (max-width: 575.98px) {
  .siteNavbar .navbar .navbar-brand.is-img img,
.section-navbar .navbar .navbar-brand.is-img img {
    height: 2.7rem;
  }
}
.siteNavbar .navbar .navbar-toggler,
.section-navbar .navbar .navbar-toggler {
  border-color: rgba(187, 215, 246, 0.5);
  padding: 0.5rem;
  border-width: 2px;
}
@media (max-width: 575.98px) {
  .siteNavbar .navbar .navbar-toggler .svg,
.section-navbar .navbar .navbar-toggler .svg {
    height: 1.35rem;
  }
}

.mobile-menu-list {
  padding: 0 0.8rem;
}

.social-menu {
  /* a[href*="tumblr.com"]:before { content: "\f173"; }*/
  /*a[href*="vimeo.com"]:before { content: "\f27d"; }
  a[href*="wordpress.com"]:before,
  a[href*="wordpress.org"]:before { content: "\f19a"; }*/
  /* a[href*="twitch.tv"]:before { content: "\f1e8"; }*/
}
.social-menu a[href*="codepen.io"]:hover, .social-menu a[href*="codepen.io"]:focus {
  color: #2F2F9B;
}
.social-menu a[href*="digg.com"]:hover, .social-menu a[href*="digg.com"]:focus {
  color: #0dcaf0;
}
.social-menu a[href*="dribbble.com"]:hover, .social-menu a[href*="dribbble.com"]:focus,
.social-menu a[href*="flickr.com"]:hover,
.social-menu a[href*="flickr.com"]:focus {
  color: #C73B6F;
}
.social-menu a[href*="dropbox.com"]:hover, .social-menu a[href*="dropbox.com"]:focus {
  color: #3d9ae8;
}
.social-menu a[href*="facebook.com"]:hover, .social-menu a[href*="facebook.com"]:focus {
  color: #3b5998;
}
.social-menu a[href*="foursquare.com"]:hover, .social-menu a[href*="foursquare.com"]:focus {
  color: #ffc107;
}
.social-menu a[href*="plus.google.com"]:hover, .social-menu a[href*="plus.google.com"]:focus {
  color: #dc3545;
}
.social-menu a[href*="github.com"]:hover, .social-menu a[href*="github.com"]:focus {
  color: #0dcaf0;
}
.social-menu a[href*="instagram.com"]:hover, .social-menu a[href*="instagram.com"]:focus {
  color: #8a3ab9;
}
.social-menu a[href*="linkedin.com"]:hover, .social-menu a[href*="linkedin.com"]:focus {
  color: #4875B4;
}
.social-menu a[href*="pinterest.com"]:hover, .social-menu a[href*="pinterest.com"]:focus {
  color: #dc3545;
}
.social-menu a[href*="reddit.com"]:hover, .social-menu a[href*="reddit.com"]:focus {
  color: #C73B6F;
}
.social-menu a[href*="skype.com"]:hover, .social-menu a[href*="skype.com"]:focus {
  color: #0dcaf0;
}
.social-menu a[href*="stumbleupon.com"]:hover, .social-menu a[href*="stumbleupon.com"]:focus {
  color: #f74425;
}
.social-menu a[href*="twitter.com"]:hover, .social-menu a[href*="twitter.com"]:focus {
  color: #0dcaf0;
}
.social-menu a[href*="youtube.com"]:hover, .social-menu a[href*="youtube.com"]:focus {
  color: #dc3545;
}
.social-menu a[href^="mailto:"]:hover, .social-menu a[href^="mailto:"]:focus {
  color: #2F2F9B;
}
.social-menu a[href*="spotify.com"]:hover, .social-menu a[href*="spotify.com"]:focus {
  color: #2F2F9B;
}
.social-menu a[href$="/feed/"]:hover, .social-menu a[href$="/feed/"]:focus {
  color: #ffc107;
}
.social-menu a:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Brands";
  font-size: 150%;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  vertical-align: middle;
}
.social-menu a[href*="codepen.io"]:before {
  content: "\f1cb";
}
.social-menu a[href*="digg.com"]:before {
  content: "\f1a6";
}
.social-menu a[href*="dribbble.com"]:before {
  content: "\f17d";
}
.social-menu a[href*="dropbox.com"]:before {
  content: "\f16b";
}
.social-menu a[href*="facebook.com"]:before {
  content: "\f39e";
}
.social-menu a[href*="flickr.com"]:before {
  content: "\f16e";
}
.social-menu a[href*="foursquare.com"]:before {
  content: "\f180";
}
.social-menu a[href*="plus.google.com"]:before {
  content: "\f0d5";
}
.social-menu a[href*="github.com"]:before {
  content: "\f09b";
}
.social-menu a[href*="instagram.com"]:before {
  content: "\f16d";
}
.social-menu a[href*="linkedin.com"]:before {
  content: "\f0e1";
}
.social-menu a[href*="pinterest.com"]:before {
  content: "\f231";
}
.social-menu a[href*="reddit.com"]:before {
  content: "\f281";
}
.social-menu a[href*="skype.com"]:before {
  content: "\f17e";
}
.social-menu a[href*="stumbleupon.com"]:before {
  content: "\f1a4";
}
.social-menu a[href*="twitter.com"]:before {
  content: "\f099";
}
.social-menu a[href*="youtube.com"]:before {
  content: "\f167";
}
.social-menu a[href^="mailto:"]:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0e0";
}
.social-menu a[href*="spotify.com"]:before {
  content: "\f1bc";
}
.social-menu a[href$="/feed/"]:before {
  content: "\f09e";
}

label {
  font-weight: 400;
  font-size: 90%;
}

.form-control {
  border-width: 1px;
}
.form-control.floating-textarea {
  height: auto;
  min-height: calc(6rem + 2px);
}
.form-control.floating-textarea textarea,
.form-control.floating-textarea .resize-textarea {
  display: flex;
  width: 100%;
  border: none;
  min-height: 3rem;
  outline: 0;
  outline-offset: 0;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}
.form-group .error {
  font-size: 75%;
  color: #dc3545;
  margin-left: 0.5rem;
}
.form-group .control-label {
  font-size: 85%;
  font-weight: 400;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  position: absolute;
  left: 16px;
  z-index: 3;
  top: 0.875em;
  color: #4646c7;
  line-height: 1;
  border-radius: 0.3rem;
  padding: 2px 5px;
  transition: 0.15s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-group .control-label {
    transition: none;
  }
}
.form-group label.select-label {
  position: relative;
  top: auto;
  left: auto;
  padding: 0;
  color: #000;
  font-weight: 600;
  margin-bottom: 0.4rem;
}
.form-group.label-floating .form-control {
  background-color: transparent;
}
.form-group.label-floating .form-control {
  position: relative;
  z-index: 10;
}
.form-group.label-floating.is-value .control-label, .form-group.label-floating.is-focused .control-label, .form-group.label-floating.is-filled .control-label {
  z-index: 20;
}
.form-group.text-floating .control-label {
  background-color: #fff;
  color: #2F2F9B;
  font-size: 75%;
}
.form-group.text-floating.is-focused .control-label, .form-group.text-floating.is-filled .control-label {
  z-index: 20;
}
.form-group.text-floating label,
.form-group.text-floating .control-label {
  position: relative;
  top: auto;
  left: auto;
  padding: 0;
}
.form-group.text-verified {
  position: relative;
}
.form-group.text-verified .control-label {
  background-color: transparent;
  color: #6c757d;
  font-size: 75%;
}
.form-group.text-verified .text-control {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 110%;
  font-weight: 500;
}
.form-group.text-verified .help-text {
  text-align: right;
  font-size: 70%;
}
.form-group.text-verified .help-edit {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: pointer;
  font-size: 65%;
  font-weight: 600;
  margin-left: 0.6rem;
}
.form-group.text-verified .help-edit:hover, .form-group.text-verified .help-edit:focus {
  filter: alpha(opacity=100);
  opacity: 1;
}
.form-group.text-verified .help-edit img {
  margin-right: 0.1rem;
}
.form-group.is-focused .control-label, .form-group.selected .control-label {
  top: -0.6rem;
  background-color: #fff;
  color: #2F2F9B;
  font-size: 75%;
}
.form-group.is-filled .control-label, .form-group.is-value .control-label {
  top: -0.4rem;
  background-color: #fff;
  font-size: 75%;
}

.form-password {
  text-align: center;
  margin: 1rem auto;
}

.view-password {
  cursor: pointer;
  font-weight: 600;
  font-size: 0.8rem;
}

.move-top {
  position: fixed;
  right: 20px;
  bottom: 15px;
  padding: 0.25rem 0;
  width: calc(1.5em + 0.5rem + 2px);
  height: calc(1.5em + 0.5rem + 2px);
  text-align: center;
  font-size: 120%;
  border-radius: 50%;
  z-index: 999;
  background: #fff;
  color: #3FA9F5;
  border: 1px solid #3FA9F5;
}
.move-top:hover {
  background-color: #3FA9F5;
  color: #fff;
  border-color: #0f93f2;
}

.page-footer {
  background-color: #17174d;
  padding-top: 2rem;
}
.page-footer.no-padding {
  padding: 0;
}
.page-footer ul {
  margin: 0;
  padding: 0;
}
.page-footer ul li {
  list-style: none;
}

.footer-icons {
  position: relative;
}
.footer-icons:after {
  content: "";
  border-radius: 0.2rem;
  background-color: transparent;
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  z-index: 2;
  height: 0.2rem;
}
.footer-icons a {
  display: block;
}
.footer-icons a img {
  display: block;
  margin: 0 auto;
  width: 3.54rem;
  height: 3.5rem;
}
.footer-icons a svg,
.footer-icons a .svg {
  display: block;
  margin: 0 auto;
  width: 3.54rem;
  height: 3.5rem;
}

.footer-list {
  margin: 0;
  padding: 0;
}
.footer-list li {
  list-style: none;
}
.footer-list li:hover svg,
.footer-list li:hover .svg {
  fill: #2F2F9B;
}
.footer-list li:hover svg path,
.footer-list li:hover .svg path {
  fill: #2F2F9B;
}
.footer-list li.active .footer-icons:after {
  background-color: #2F2F9B;
}
.footer-list li.active .footer-icons svg,
.footer-list li.active .footer-icons .svg {
  fill: #2F2F9B;
}
.footer-list li.active .footer-icons svg path,
.footer-list li.active .footer-icons .svg path {
  fill: #2F2F9B;
}
.footer-app {
  margin: 1.2rem auto;
}
.footer-app .header {
  margin: 0;
  padding: 0;
  margin-right: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}
.footer-app .svg-link {
  display: inline-block;
}
.footer-app .svg-link .svg {
  height: 2.25rem;
}
@media (max-width: 575.98px) {
  .footer-app .svg-link .svg {
    height: 2.25rem;
  }
}
.footer-nav {
  margin: 1.2rem auto;
}
.footer-nav.no-margin {
  margin: 0.2rem auto;
}
.footer-nav li {
  padding: 0;
  margin: 0.5rem 0;
  border-right: 1px solid #7e8b98;
  line-height: 1;
}
@media (max-width: 575.98px) {
  .footer-nav li {
    font-size: 0.8rem;
  }
}
.footer-nav li:last-child {
  border: none;
}
.footer-nav li a {
  line-height: 1;
  padding: 0 0.8rem;
  filter: alpha(opacity=80);
  opacity: 0.8;
  font-weight: 500;
  color: #fff;
}
.footer-nav li a:hover, .footer-nav li a:focus {
  filter: alpha(opacity=100);
  opacity: 1;
  color: #0dcaf0;
}
.footer-soical {
  margin-bottom: 1.2rem;
}
.footer-soical .text {
  display: none;
}
.footer-soical a {
  display: block;
  font-size: 1.2rem;
  width: 3rem;
  height: 3rem;
  color: #fff;
  filter: alpha(opacity=70);
  opacity: 0.7;
}
.footer-address .header {
  margin: 0;
  padding: 0;
}
.footer-copyright {
  margin-top: 1.2rem;
  font-size: 0.9rem;
}
.footer-copyright-inner {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding: 0.8rem 0;
}
.footer-copyright li {
  padding: 0 1.5rem;
  margin: auto 0.2rem;
  border-right: 1px solid #7e8b98;
  line-height: 1;
  color: #fff;
  filter: alpha(opacity=80);
  opacity: 0.8;
}
.footer-copyright li:last-child {
  border: none;
}
.footer-copyright li a {
  line-height: 1;
  display: inline-block;
  font-weight: 500;
  color: #fff;
}
.footer-copyright li a:hover, .footer-copyright li a:focus {
  color: #0dcaf0;
  filter: alpha(opacity=100);
  opacity: 1;
}
@media (max-width: 575.98px) {
  .footer-copyright {
    font-size: 0.7rem;
  }
  .footer-copyright li {
    margin: 0.2rem auto;
    border: none;
  }
}

.icons-list {
  display: flex;
  align-items: center;
}
.icons-list:last-child {
  margin-right: 0;
}
.icons-list:last-child .title-date {
  margin-right: 0;
}
.icons-list.with-img {
  margin-right: 1.5rem;
}
.icons-list.with-img svg {
  fill: #6874a7;
}
.icons-list.with-img svg path {
  fill: #6874a7;
}
.icons-list.with-img:hover, .icons-list.with-img.active {
  color: #2F2F9B;
}
.icons-list.with-img:hover svg, .icons-list.with-img.active svg {
  fill: #2F2F9B;
}
.icons-list.with-img:hover svg path, .icons-list.with-img.active svg path {
  fill: #2F2F9B;
}
.icons-list .title-icons {
  width: 2.3rem;
}
.icons-list .title-icons img {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.icons-list .image-ico {
  display: flex;
  width: 2rem;
  margin-right: 0.5rem;
}
.icons-list .image-ico img {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.icons-list .image-ico.mini {
  width: 1.15rem;
}
.icons-list .image-ico.with-icons {
  width: auto;
}
.icons-list .image-ico.with-icons .icons {
  font-size: 0.4rem;
  padding-top: 0.1rem;
}
.icons-list .image-ico.with-images {
  width: 1.9rem;
  margin-right: 0.5rem;
}
.icons-list .image-ico.with-images img {
  display: block;
  width: 100%;
}
.icons-list .image-ico .icons {
  font-size: 0.5rem;
}
.icons-list.only-fa {
  font-size: 1.5rem;
  padding: 0 0.6rem;
}
.icons-list .text {
  color: #6874a7;
  margin-right: 1.5rem;
}
.icons-list .text.with-date {
  color: #6874a7;
  font-family: "Fira Sans", sans-serif;
}
.icons-list .title {
  font-size: 2rem;
  font-weight: 600;
}
.icons-list .hero-title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 0.5;
}
.icons-list .title-text {
  font-size: 1.2rem;
  font-weight: 600;
}
.icons-list .heading {
  font-size: 1.55rem;
  font-weight: 800;
}
.icons-list .sub-title {
  font-size: 1.5rem;
}
.icons-list .title-content {
  font-size: 0.9rem;
}
.icons-list .mini-title {
  font-size: 0.9rem;
  color: #6874a7;
}
.icons-list .title-email {
  font-size: 0.9rem;
  font-family: "Fira Sans", sans-serif;
  color: #6874a7;
}
.icons-list .title-date {
  font-size: 0.9rem;
  font-family: "Fira Sans", sans-serif;
  margin-right: 1.2rem;
}
.icons-list .title-date .icons {
  margin-right: 0.5rem;
}
.icons-list .title-date .image {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
}
.icons-list.only-icon {
  padding: 0.85rem 0.7rem;
}
.icons-list.only-icon .image {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
}
.icons-list.files-card {
  cursor: pointer;
}

.resend-btn {
  cursor: pointer;
}
.resend-btn.disable {
  pointer-events: none;
  filter: alpha(opacity=60);
  opacity: 0.6;
}

.avatar-card {
  display: flex;
  align-items: center;
  padding-bottom: 0;
  margin: 0.1rem auto;
  border-radius: 0.2rem;
}
.avatar-card.with-border {
  border: 1px solid rgba(187, 215, 246, 0.5);
  margin: 0 auto;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  padding: 0.8rem 1.2rem;
}
.avatar-card.with-close {
  margin: 0 auto;
  margin-bottom: 3rem;
}
.avatar-card.with-margin {
  border: 1px solid rgba(187, 215, 246, 0.5);
  margin: 0 auto;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  padding: 0.8rem 1.2rem;
}
.avatar-card.with-margin:hover,
.avatar-card.with-margin .active {
  border-color: #2F2F9B;
}
.avatar-card.with-margin:hover .card-text,
.avatar-card.with-margin .active .card-text {
  color: #2F2F9B;
}
.avatar-card .card-icon {
  color: #212529;
  min-width: 3rem;
  max-width: 3rem;
  margin-right: 1.2rem;
}
.avatar-card .card-icon-xs {
  min-width: 3rem;
  max-width: 3rem;
}
.avatar-card .card-icon-sm {
  min-width: 4rem;
  max-width: 4rem;
}
.avatar-card .card-icon-md {
  min-width: 6rem;
  max-width: 6rem;
}
.avatar-card .card-icon-xl {
  min-width: 8rem;
  max-width: 8rem;
}
.avatar-card .card-content {
  color: #212529;
  max-width: 9rem;
  padding-left: 0.5rem;
}
.avatar-card .card-content .btns {
  border-radius: 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
.avatar-card .image {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 3;
}
.avatar-card .image .num {
  position: absolute;
  z-index: 2;
  right: 0.4rem;
  bottom: 0.7rem;
  font-size: 1.1rem;
  color: #6874a7;
  font-family: "Fira Sans", sans-serif;
  line-height: 0.8;
  font-weight: 900;
  letter-spacing: -1px;
}
.avatar-card .image-xs {
  width: 3rem;
  height: 3rem;
}
.avatar-card .image-sm {
  width: 4rem;
  height: 4rem;
}
.avatar-card .image-md {
  width: 6rem;
  height: 6rem;
}
.avatar-card .image-xl {
  width: 8rem;
  height: 8rem;
}
.avatar-card .image img {
  display: block;
  width: 100%;
}
.avatar-card .figure {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 3;
}
.avatar-card .figure-sm {
  width: 4rem;
  height: 4rem;
}
.avatar-card .figure-md {
  width: 6rem;
  height: 6rem;
}
.avatar-card .figure-xl {
  width: 8rem;
  height: 8rem;
}
.avatar-card .figure img {
  display: block;
  width: 100%;
}
.avatar-card-fig {
  min-height: 415px;
  border: 1px solid rgba(187, 215, 246, 0.5);
  margin: 0 auto;
  border-radius: 0.3rem;
  padding: 1.8rem;
  background-color: #ccf3f9;
}
.avatar-card-fig .image {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 3;
  margin-bottom: 2.5rem;
}
.avatar-card-fig .image .num {
  position: absolute;
  z-index: 2;
  right: 0.4rem;
  bottom: 0.7rem;
  font-size: 1.1rem;
  color: #6874a7;
  font-family: "Fira Sans", sans-serif;
  line-height: 0.8;
  font-weight: 900;
  letter-spacing: -1px;
}
.avatar-card-fig .image-xs {
  width: 3rem;
  height: 3rem;
}
.avatar-card-fig .image-sm {
  width: 4rem;
  height: 4rem;
}
.avatar-card-fig .image-md {
  width: 6rem;
  height: 6rem;
}
.avatar-card-fig .image-xl {
  width: 8rem;
  height: 8rem;
}
.avatar-card-fig .image img {
  display: block;
  width: 100%;
}
.avatar-card-fig-nav {
  margin-bottom: 2rem;
}
.avatar-card-fig .title-meta {
  margin-bottom: 1.2rem;
}
.avatar-card-fig .title-text {
  font-size: 1.15rem;
  font-weight: 700;
  line-height: 1.3;
}
.avatar-card-fig .title-content {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
}
.avatar-card .card-text .badge {
  margin-left: 0.8rem;
  font-size: 0.75rem;
}
.avatar-card .card-text .title {
  font-size: 1.55rem;
  font-weight: 600;
}
.avatar-card .card-text .header {
  margin: 0;
  font-size: 1.35rem;
  font-weight: 600;
}
.avatar-card .card-text .title-text {
  font-size: 1.2rem;
  font-weight: 600;
}
.avatar-card .card-text .heading {
  font-size: 1.55rem;
  font-weight: 800;
}
.avatar-card .card-text .designation {
  font-size: 1.1rem;
  font-weight: 800;
  line-height: 1.1;
}
.avatar-card .card-text .name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 1.9rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 575.98px) {
  .avatar-card .card-text .name {
    font-size: 1.3rem;
  }
}
.avatar-card .card-text .address {
  font-size: 1rem;
}
.avatar-card .card-text .sub-title {
  font-size: 2rem;
}
.avatar-card .card-text .title-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 0.9rem;
  margin-bottom: 1.6rem;
}
.avatar-card .card-text .title-content p {
  margin: 0;
}
.avatar-card .card-text .title-content.auto {
  -webkit-line-clamp: initial;
  height: auto;
  margin: 0;
}
.avatar-card .card-text .title-content.auto p {
  margin: 0;
}
.avatar-card .card-text .mini-title {
  font-size: 0.9rem;
  color: #6874a7;
}
.avatar-card .card-text .title-qus {
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 600;
}
.avatar-card .card-text .title-email {
  font-size: 0.9rem;
  font-family: "Fira Sans", sans-serif;
  color: #6874a7;
}
.avatar-card .card-text .title-date {
  font-size: 0.9rem;
  font-family: "Fira Sans", sans-serif;
  color: #6874a7;
  margin-right: 0.9rem;
}
.avatar-card .meta-header {
  margin-bottom: 0.8rem;
}
.avatar-card .meta-tags {
  margin-bottom: 1.2rem;
}
.avatar-card .card-badge {
  padding: 0.2rem 0.5rem;
  color: #212529;
}
.avatar-card .card-badge .title {
  font-size: 1.55rem;
  font-weight: 600;
}
.avatar-card .card-badge .heading {
  font-size: 1.55rem;
  font-weight: 700;
}
.avatar-card .card-badge .sub-heading {
  font-size: 1.55rem;
}
.avatar-card .card-badge .sub-title {
  font-size: 2rem;
}
.avatar-card .card-badge .mini-title {
  font-size: 0.8rem;
}
.avatar-card .card-badge .title-email {
  font-size: 0.9rem;
  font-family: "Fira Sans", sans-serif;
  color: #6874a7;
}

.mobile-menu-ul {
  position: relative;
  padding: 0.5rem;
  margin-top: 2rem;
}
.mobile-menu-ul:after {
  content: "";
  border-radius: 0.2rem;
  background-color: #2F2F9B;
  position: absolute;
  top: 0;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 2;
  height: 0.2rem;
}
.mobile-menu-ul .heading {
  padding-top: 1rem;
  padding-left: 1rem;
}

.date-info.mini {
  padding-top: 2.3rem;
}
.date-info .days {
  font-size: 0.8rem;
  color: #6874a7;
}
.date-info .years {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1;
  font-size: 1.3rem;
  color: #6874a7;
}

.icons-img img {
  min-height: 1rem;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 3;
}

.meta-section {
  margin-bottom: 1.5rem;
}
.meta-section .title-text {
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 800;
}
.meta-section .heading {
  font-size: 1.55rem;
  font-weight: 800;
}
.meta-section .sub-title {
  font-size: 2rem;
}
.meta-section .title-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 0.9rem;
  margin-bottom: 1.6rem;
}
.meta-section .mini-title {
  font-size: 0.9rem;
  color: #6874a7;
}
.meta-section .title-email {
  font-size: 0.9rem;
  font-family: "Fira Sans", sans-serif;
  color: #6874a7;
}
.meta-section .title-date {
  font-size: 0.9rem;
  font-family: "Fira Sans", sans-serif;
  color: #6874a7;
  margin-right: 0.9rem;
}

.text-quotes {
  margin: 2.5rem auto;
}

.uploded-file-list {
  margin: 0;
  padding: 0;
}
.uploded-file-list.has-overflow {
  padding-right: 2.5rem;
}
.uploded-file-list li {
  list-style: none;
}

.no-display,
.nodisplay,
.hiddendiv {
  display: none;
}

.icons-card {
  color: #212529;
  min-width: 3rem;
  max-width: 3rem;
  margin-right: 1.2rem;
}
.icons-card-xs {
  min-width: 3rem;
  max-width: 3rem;
}
.icons-card-sm {
  min-width: 4rem;
  max-width: 4rem;
}
.icons-card-md {
  min-width: 5rem;
  max-width: 5rem;
}
.icons-card-xl {
  min-width: 8rem;
  max-width: 8rem;
}
.icons-card .image {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 3;
}
.icons-card .image .num {
  position: absolute;
  z-index: 2;
  right: 0.4rem;
  bottom: 0.7rem;
  font-size: 1.1rem;
  color: #6874a7;
  font-family: "Fira Sans", sans-serif;
  line-height: 0.8;
  font-weight: 900;
  letter-spacing: -1px;
}
.icons-card .image-xs {
  width: 3rem;
  height: 3rem;
}
.icons-card .image-sm {
  width: 4rem;
  height: 4rem;
}
.icons-card .image-md {
  width: 5rem;
  height: 5rem;
}
.icons-card .image-xl {
  width: 8rem;
  height: 8rem;
}
.icons-card .image .icons-img {
  display: block;
  width: 100%;
}
.icons-card .image .svg {
  display: block;
  width: 100%;
  height: 100%;
}

.card-offcanvas-menu {
  display: flex;
  align-items: center;
  padding: 0.65rem 0.8rem;
  padding-bottom: 0.7rem;
  text-align: center;
  border: 1px solid transparent;
  margin: 0.1rem auto;
  border-radius: 0.2rem;
}
.card-offcanvas-menu .card-icon {
  color: #212529;
  max-width: 3rem;
  padding-right: 1.2rem;
}
.card-offcanvas-menu .image {
  display: inline-block;
  width: 1.65rem;
  height: 1.65rem;
  position: relative;
  z-index: 3;
}
.card-offcanvas-menu .image img {
  display: block;
  width: 100%;
}
.card-offcanvas-menu .card-text {
  color: #212529;
  vertical-align: top;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 0.1rem;
}
.card-offcanvas-menu:hover, .card-offcanvas-menu.active {
  border-color: #2F2F9B;
}
.card-offcanvas-menu:hover .card-text, .card-offcanvas-menu.active .card-text {
  color: #2F2F9B;
}
.card-offcanvas-menu:hover .image svg, .card-offcanvas-menu.active .image svg {
  fill: #3FA9F5;
}
.card-offcanvas-menu:hover .image svg path, .card-offcanvas-menu.active .image svg path {
  fill: #3FA9F5;
}

.mobile-menu-list li.active .card-offcanvas-menu {
  border-color: #2F2F9B;
}
.mobile-menu-list li.active .card-offcanvas-menu .card-text {
  color: #2F2F9B;
}
.mobile-menu-list li.active .card-offcanvas-menu .image svg {
  fill: #3FA9F5;
}
.mobile-menu-list li.active .card-offcanvas-menu .image svg path {
  fill: #3FA9F5;
}

.mobile-menu-ul {
  position: relative;
  padding: 0.5rem;
  margin-top: 2rem;
}
.mobile-menu-ul:after {
  content: "";
  border-radius: 0.2rem;
  background-color: rgba(187, 215, 246, 0.5);
  position: absolute;
  top: 0;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 2;
  height: 0.2rem;
}
.mobile-menu-ul .heading {
  padding-top: 1rem;
  padding-left: 1rem;
}

.offcanvas-header .logo.svg {
  height: 1.5rem;
}
@media (max-width: 575.98px) {
  .offcanvas-header .logo.svg {
    height: 1.5rem;
  }
}
.offcanvas-header.with-bg {
  align-items: flex-start;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1.2rem;
  position: relative;
}
.offcanvas-header.with-bg .offcanvas-title {
  padding: 1.5rem 0;
  padding-top: 2rem;
}
.offcanvas-header.with-bg:after {
  content: "";
  border-radius: 0.2rem;
  background-color: rgba(187, 215, 246, 0.5);
  position: absolute;
  bottom: 0;
  left: 2rem;
  right: 2rem;
  z-index: 2;
  height: 0.2rem;
}

.text-card {
  padding: 0.8rem 0;
}
.text-card .title {
  font-size: 1.55rem;
  font-weight: 600;
}
.text-card .badge-title {
  color: #6874a7;
  font-size: 0.85rem;
  font-weight: 600;
}
.text-card .sub-title {
  font-size: 2rem;
}
.text-card .lg-title {
  font-size: 1.4rem;
  font-weight: 300;
}
.text-card .mini-title {
  font-size: 0.9rem;
  color: #6874a7;
}
.text-card .title-email {
  font-size: 0.9rem;
  font-family: "Fira Sans", sans-serif;
  color: #6874a7;
}

.text-justify {
  text-align: justify;
}

.card-progress {
  width: 140px;
  height: 140px;
  line-height: 140px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}
.card-progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0.5rem solid #e9f7fd;
  position: absolute;
  top: 0;
  left: 0;
}
.card-progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.card-progress .card-progress-left {
  left: 0;
}
.card-progress .card-progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 0.5rem;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #3FA9F5;
}
.card-progress .card-progress-left .card-progress-bar {
  left: 100%;
  border-top-right-radius: 70px;
  border-bottom-right-radius: 70px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.card-progress .card-progress-right {
  right: 0;
}
.card-progress .card-progress-right .card-progress-bar {
  left: -100%;
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.card-progress .card-progress-value {
  display: flex;
  border-radius: 50%;
  text-align: center;
  color: #151313;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  line-height: 0.7rem;
  font-family: "Fira Sans", sans-serif;
}
.card-progress .card-progress-value .text {
  font-size: 3rem;
  line-height: 2.5rem;
}
.card-progress .card-progress-value .mini {
  font-size: 1.5rem;
}
.card-progress .card-progress-value .small {
  display: block;
  font-size: 1rem;
}

/* This for loop creates the necessary css animation names 
Due to the split circle of card-progress-left and card-progress right, we must use the animations on each side. 
*/
.card-progress[data-percentage="1"] .card-progress-right .card-progress-bar {
  animation: loading-1 0.5s linear forwards;
}
.card-progress[data-percentage="1"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="2"] .card-progress-right .card-progress-bar {
  animation: loading-2 0.5s linear forwards;
}
.card-progress[data-percentage="2"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="3"] .card-progress-right .card-progress-bar {
  animation: loading-3 0.5s linear forwards;
}
.card-progress[data-percentage="3"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="4"] .card-progress-right .card-progress-bar {
  animation: loading-4 0.5s linear forwards;
}
.card-progress[data-percentage="4"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="5"] .card-progress-right .card-progress-bar {
  animation: loading-5 0.5s linear forwards;
}
.card-progress[data-percentage="5"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="6"] .card-progress-right .card-progress-bar {
  animation: loading-6 0.5s linear forwards;
}
.card-progress[data-percentage="6"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="7"] .card-progress-right .card-progress-bar {
  animation: loading-7 0.5s linear forwards;
}
.card-progress[data-percentage="7"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="8"] .card-progress-right .card-progress-bar {
  animation: loading-8 0.5s linear forwards;
}
.card-progress[data-percentage="8"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="9"] .card-progress-right .card-progress-bar {
  animation: loading-9 0.5s linear forwards;
}
.card-progress[data-percentage="9"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="10"] .card-progress-right .card-progress-bar {
  animation: loading-10 0.5s linear forwards;
}
.card-progress[data-percentage="10"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="11"] .card-progress-right .card-progress-bar {
  animation: loading-11 0.5s linear forwards;
}
.card-progress[data-percentage="11"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="12"] .card-progress-right .card-progress-bar {
  animation: loading-12 0.5s linear forwards;
}
.card-progress[data-percentage="12"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="13"] .card-progress-right .card-progress-bar {
  animation: loading-13 0.5s linear forwards;
}
.card-progress[data-percentage="13"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="14"] .card-progress-right .card-progress-bar {
  animation: loading-14 0.5s linear forwards;
}
.card-progress[data-percentage="14"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="15"] .card-progress-right .card-progress-bar {
  animation: loading-15 0.5s linear forwards;
}
.card-progress[data-percentage="15"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="16"] .card-progress-right .card-progress-bar {
  animation: loading-16 0.5s linear forwards;
}
.card-progress[data-percentage="16"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="17"] .card-progress-right .card-progress-bar {
  animation: loading-17 0.5s linear forwards;
}
.card-progress[data-percentage="17"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="18"] .card-progress-right .card-progress-bar {
  animation: loading-18 0.5s linear forwards;
}
.card-progress[data-percentage="18"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="19"] .card-progress-right .card-progress-bar {
  animation: loading-19 0.5s linear forwards;
}
.card-progress[data-percentage="19"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="20"] .card-progress-right .card-progress-bar {
  animation: loading-20 0.5s linear forwards;
}
.card-progress[data-percentage="20"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="21"] .card-progress-right .card-progress-bar {
  animation: loading-21 0.5s linear forwards;
}
.card-progress[data-percentage="21"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="22"] .card-progress-right .card-progress-bar {
  animation: loading-22 0.5s linear forwards;
}
.card-progress[data-percentage="22"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="23"] .card-progress-right .card-progress-bar {
  animation: loading-23 0.5s linear forwards;
}
.card-progress[data-percentage="23"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="24"] .card-progress-right .card-progress-bar {
  animation: loading-24 0.5s linear forwards;
}
.card-progress[data-percentage="24"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="25"] .card-progress-right .card-progress-bar {
  animation: loading-25 0.5s linear forwards;
}
.card-progress[data-percentage="25"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="26"] .card-progress-right .card-progress-bar {
  animation: loading-26 0.5s linear forwards;
}
.card-progress[data-percentage="26"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="27"] .card-progress-right .card-progress-bar {
  animation: loading-27 0.5s linear forwards;
}
.card-progress[data-percentage="27"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="28"] .card-progress-right .card-progress-bar {
  animation: loading-28 0.5s linear forwards;
}
.card-progress[data-percentage="28"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="29"] .card-progress-right .card-progress-bar {
  animation: loading-29 0.5s linear forwards;
}
.card-progress[data-percentage="29"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="30"] .card-progress-right .card-progress-bar {
  animation: loading-30 0.5s linear forwards;
}
.card-progress[data-percentage="30"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="31"] .card-progress-right .card-progress-bar {
  animation: loading-31 0.5s linear forwards;
}
.card-progress[data-percentage="31"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="32"] .card-progress-right .card-progress-bar {
  animation: loading-32 0.5s linear forwards;
}
.card-progress[data-percentage="32"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="33"] .card-progress-right .card-progress-bar {
  animation: loading-33 0.5s linear forwards;
}
.card-progress[data-percentage="33"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="34"] .card-progress-right .card-progress-bar {
  animation: loading-34 0.5s linear forwards;
}
.card-progress[data-percentage="34"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="35"] .card-progress-right .card-progress-bar {
  animation: loading-35 0.5s linear forwards;
}
.card-progress[data-percentage="35"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="36"] .card-progress-right .card-progress-bar {
  animation: loading-36 0.5s linear forwards;
}
.card-progress[data-percentage="36"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="37"] .card-progress-right .card-progress-bar {
  animation: loading-37 0.5s linear forwards;
}
.card-progress[data-percentage="37"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="38"] .card-progress-right .card-progress-bar {
  animation: loading-38 0.5s linear forwards;
}
.card-progress[data-percentage="38"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="39"] .card-progress-right .card-progress-bar {
  animation: loading-39 0.5s linear forwards;
}
.card-progress[data-percentage="39"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="40"] .card-progress-right .card-progress-bar {
  animation: loading-40 0.5s linear forwards;
}
.card-progress[data-percentage="40"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="41"] .card-progress-right .card-progress-bar {
  animation: loading-41 0.5s linear forwards;
}
.card-progress[data-percentage="41"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="42"] .card-progress-right .card-progress-bar {
  animation: loading-42 0.5s linear forwards;
}
.card-progress[data-percentage="42"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="43"] .card-progress-right .card-progress-bar {
  animation: loading-43 0.5s linear forwards;
}
.card-progress[data-percentage="43"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="44"] .card-progress-right .card-progress-bar {
  animation: loading-44 0.5s linear forwards;
}
.card-progress[data-percentage="44"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="45"] .card-progress-right .card-progress-bar {
  animation: loading-45 0.5s linear forwards;
}
.card-progress[data-percentage="45"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="46"] .card-progress-right .card-progress-bar {
  animation: loading-46 0.5s linear forwards;
}
.card-progress[data-percentage="46"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="47"] .card-progress-right .card-progress-bar {
  animation: loading-47 0.5s linear forwards;
}
.card-progress[data-percentage="47"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="48"] .card-progress-right .card-progress-bar {
  animation: loading-48 0.5s linear forwards;
}
.card-progress[data-percentage="48"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="49"] .card-progress-right .card-progress-bar {
  animation: loading-49 0.5s linear forwards;
}
.card-progress[data-percentage="49"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="50"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.card-progress[data-percentage="50"] .card-progress-left .card-progress-bar {
  animation: 0;
}

.card-progress[data-percentage="51"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="51"] .card-progress-left .card-progress-bar {
  animation: loading-1 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="52"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="52"] .card-progress-left .card-progress-bar {
  animation: loading-2 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="53"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="53"] .card-progress-left .card-progress-bar {
  animation: loading-3 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="54"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="54"] .card-progress-left .card-progress-bar {
  animation: loading-4 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="55"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="55"] .card-progress-left .card-progress-bar {
  animation: loading-5 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="56"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="56"] .card-progress-left .card-progress-bar {
  animation: loading-6 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="57"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="57"] .card-progress-left .card-progress-bar {
  animation: loading-7 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="58"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="58"] .card-progress-left .card-progress-bar {
  animation: loading-8 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="59"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="59"] .card-progress-left .card-progress-bar {
  animation: loading-9 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="60"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="60"] .card-progress-left .card-progress-bar {
  animation: loading-10 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="61"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="61"] .card-progress-left .card-progress-bar {
  animation: loading-11 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="62"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="62"] .card-progress-left .card-progress-bar {
  animation: loading-12 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="63"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="63"] .card-progress-left .card-progress-bar {
  animation: loading-13 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="64"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="64"] .card-progress-left .card-progress-bar {
  animation: loading-14 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="65"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="65"] .card-progress-left .card-progress-bar {
  animation: loading-15 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="66"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="66"] .card-progress-left .card-progress-bar {
  animation: loading-16 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="67"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="67"] .card-progress-left .card-progress-bar {
  animation: loading-17 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="68"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="68"] .card-progress-left .card-progress-bar {
  animation: loading-18 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="69"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="69"] .card-progress-left .card-progress-bar {
  animation: loading-19 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="70"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="70"] .card-progress-left .card-progress-bar {
  animation: loading-20 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="71"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="71"] .card-progress-left .card-progress-bar {
  animation: loading-21 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="72"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="72"] .card-progress-left .card-progress-bar {
  animation: loading-22 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="73"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="73"] .card-progress-left .card-progress-bar {
  animation: loading-23 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="74"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="74"] .card-progress-left .card-progress-bar {
  animation: loading-24 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="75"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="75"] .card-progress-left .card-progress-bar {
  animation: loading-25 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="76"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="76"] .card-progress-left .card-progress-bar {
  animation: loading-26 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="77"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="77"] .card-progress-left .card-progress-bar {
  animation: loading-27 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="78"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="78"] .card-progress-left .card-progress-bar {
  animation: loading-28 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="79"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="79"] .card-progress-left .card-progress-bar {
  animation: loading-29 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="80"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="80"] .card-progress-left .card-progress-bar {
  animation: loading-30 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="81"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="81"] .card-progress-left .card-progress-bar {
  animation: loading-31 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="82"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="82"] .card-progress-left .card-progress-bar {
  animation: loading-32 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="83"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="83"] .card-progress-left .card-progress-bar {
  animation: loading-33 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="84"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="84"] .card-progress-left .card-progress-bar {
  animation: loading-34 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="85"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="85"] .card-progress-left .card-progress-bar {
  animation: loading-35 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="86"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="86"] .card-progress-left .card-progress-bar {
  animation: loading-36 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="87"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="87"] .card-progress-left .card-progress-bar {
  animation: loading-37 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="88"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="88"] .card-progress-left .card-progress-bar {
  animation: loading-38 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="89"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="89"] .card-progress-left .card-progress-bar {
  animation: loading-39 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="90"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="90"] .card-progress-left .card-progress-bar {
  animation: loading-40 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="91"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="91"] .card-progress-left .card-progress-bar {
  animation: loading-41 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="92"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="92"] .card-progress-left .card-progress-bar {
  animation: loading-42 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="93"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="93"] .card-progress-left .card-progress-bar {
  animation: loading-43 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="94"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="94"] .card-progress-left .card-progress-bar {
  animation: loading-44 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="95"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="95"] .card-progress-left .card-progress-bar {
  animation: loading-45 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="96"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="96"] .card-progress-left .card-progress-bar {
  animation: loading-46 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="97"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="97"] .card-progress-left .card-progress-bar {
  animation: loading-47 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="98"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="98"] .card-progress-left .card-progress-bar {
  animation: loading-48 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="99"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="99"] .card-progress-left .card-progress-bar {
  animation: loading-49 0.5s linear forwards 0.5s;
}

.card-progress[data-percentage="100"] .card-progress-right .card-progress-bar {
  animation: loading-50 0.5s forwards;
}
.card-progress[data-percentage="100"] .card-progress-left .card-progress-bar {
  animation: loading-50 0.5s linear forwards 0.5s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(3.6);
    transform: rotate(3.6deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(7.2);
    transform: rotate(7.2deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10.8);
    transform: rotate(10.8deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(14.4);
    transform: rotate(14.4deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(18);
    transform: rotate(18deg);
  }
}
@keyframes loading-6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(21.6);
    transform: rotate(21.6deg);
  }
}
@keyframes loading-7 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(25.2);
    transform: rotate(25.2deg);
  }
}
@keyframes loading-8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(28.8);
    transform: rotate(28.8deg);
  }
}
@keyframes loading-9 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(32.4);
    transform: rotate(32.4deg);
  }
}
@keyframes loading-10 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}
@keyframes loading-11 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(39.6);
    transform: rotate(39.6deg);
  }
}
@keyframes loading-12 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(43.2);
    transform: rotate(43.2deg);
  }
}
@keyframes loading-13 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(46.8);
    transform: rotate(46.8deg);
  }
}
@keyframes loading-14 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(50.4);
    transform: rotate(50.4deg);
  }
}
@keyframes loading-15 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(54);
    transform: rotate(54deg);
  }
}
@keyframes loading-16 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(57.6);
    transform: rotate(57.6deg);
  }
}
@keyframes loading-17 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(61.2);
    transform: rotate(61.2deg);
  }
}
@keyframes loading-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(64.8);
    transform: rotate(64.8deg);
  }
}
@keyframes loading-19 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(68.4);
    transform: rotate(68.4deg);
  }
}
@keyframes loading-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}
@keyframes loading-21 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(75.6);
    transform: rotate(75.6deg);
  }
}
@keyframes loading-22 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(79.2);
    transform: rotate(79.2deg);
  }
}
@keyframes loading-23 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(82.8);
    transform: rotate(82.8deg);
  }
}
@keyframes loading-24 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(86.4);
    transform: rotate(86.4deg);
  }
}
@keyframes loading-25 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(90);
    transform: rotate(90deg);
  }
}
@keyframes loading-26 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(93.6);
    transform: rotate(93.6deg);
  }
}
@keyframes loading-27 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(97.2);
    transform: rotate(97.2deg);
  }
}
@keyframes loading-28 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(100.8);
    transform: rotate(100.8deg);
  }
}
@keyframes loading-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(104.4);
    transform: rotate(104.4deg);
  }
}
@keyframes loading-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}
@keyframes loading-31 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(111.6);
    transform: rotate(111.6deg);
  }
}
@keyframes loading-32 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(115.2);
    transform: rotate(115.2deg);
  }
}
@keyframes loading-33 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(118.8);
    transform: rotate(118.8deg);
  }
}
@keyframes loading-34 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(122.4);
    transform: rotate(122.4deg);
  }
}
@keyframes loading-35 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(126);
    transform: rotate(126deg);
  }
}
@keyframes loading-36 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(129.6);
    transform: rotate(129.6deg);
  }
}
@keyframes loading-37 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(133.2);
    transform: rotate(133.2deg);
  }
}
@keyframes loading-38 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(136.8);
    transform: rotate(136.8deg);
  }
}
@keyframes loading-39 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(140.4);
    transform: rotate(140.4deg);
  }
}
@keyframes loading-40 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}
@keyframes loading-41 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(147.6);
    transform: rotate(147.6deg);
  }
}
@keyframes loading-42 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(151.2);
    transform: rotate(151.2deg);
  }
}
@keyframes loading-43 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(154.8);
    transform: rotate(154.8deg);
  }
}
@keyframes loading-44 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(158.4);
    transform: rotate(158.4deg);
  }
}
@keyframes loading-45 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(162);
    transform: rotate(162deg);
  }
}
@keyframes loading-46 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(165.6);
    transform: rotate(165.6deg);
  }
}
@keyframes loading-47 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(169.2);
    transform: rotate(169.2deg);
  }
}
@keyframes loading-48 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(172.8);
    transform: rotate(172.8deg);
  }
}
@keyframes loading-49 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(176.4);
    transform: rotate(176.4deg);
  }
}
@keyframes loading-50 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}
@media (max-width: 767px) {
  .gc_market .row {
    flex-wrap: nowrap;
    overflow-y: auto;
  }
}
.gc_market--name {
  font-size: 15px;
  color: #262424;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: 10px;
}
.gc_market_list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gc_market_list_each {
  display: flex;
  align-items: center;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  margin: 0 20px;
}
.gc_market_list_each span {
  background: var(--data-bg);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 10px;
}

.card-slider-prev, .card-slider-next {
  padding: 0.2rem 0.5rem;
  background-color: rgba(187, 215, 246, 0.25);
  border-radius: 0.3rem;
}
.card-slider-prev:hover, .card-slider-next:hover {
  background-color: #2F2F9B;
}
.card-slider-prev:hover svg,
.card-slider-prev:hover .svg, .card-slider-next:hover svg,
.card-slider-next:hover .svg {
  fill: #fff;
}
.card-slider-prev:hover svg path,
.card-slider-prev:hover .svg path, .card-slider-next:hover svg path,
.card-slider-next:hover .svg path {
  fill: #fff;
}
.card-slider-prev.swiper-button-disabled, .card-slider-next.swiper-button-disabled {
  cursor: auto;
  pointer-events: none;
  filter: alpha(opacity=70);
  opacity: 0.7;
}
.card-slider-next {
  margin-left: 0.5rem;
}

.card-marquee .activity-nav {
  margin-top: 1rem;
}
.card-marquee .swiper-slide .marquee-text {
  padding: 0.2rem 3rem;
}

.cards {
  display: block;
}
.cards-checkbox {
  margin: auto 0.3rem;
}
.cards-checkbox .btn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.cards .figure {
  border-radius: 0.6rem;
  margin-bottom: 0.8rem;
  overflow: hidden;
}
.cards .figure img {
  display: block;
  width: 100%;
}
.cards.with-border {
  border: 1px solid rgba(187, 215, 246, 0.5);
  margin: 0.7rem auto;
  border-radius: 1.2rem;
  padding: 0.8rem;
}
.cards.with-border:hover,
.cards.with-border .active {
  border-color: #2F2F9B;
}
.cards.with-border:hover .card-text,
.cards.with-border .active .card-text {
  color: #2F2F9B;
}
.cards.figure-border .banner-svg {
  border: 1px solid rgba(187, 215, 246, 0.5);
}
.cards.figure-border .banner-svg:hover,
.cards.figure-border .banner-svg .active {
  border-color: #2F2F9B;
}
.cards.figure-border .banner-svg:hover .card-text,
.cards.figure-border .banner-svg .active .card-text {
  color: #2F2F9B;
}
@media (max-width: 575.98px) {
  .cards-lg-content.with-margin {
    margin-bottom: 3rem;
  }
}
.cards-lg-content .title {
  position: relative;
  font-size: 2.3rem;
  margin-bottom: 0.9rem;
}
@media (max-width: 575.98px) {
  .cards-lg-content .title {
    font-size: 1.8rem;
    margin-bottom: 0.4rem;
  }
}
.cards-lg-content .label {
  position: relative;
  font-family: "Red Hat Display", sans-serif;
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}
.cards-lg-content .sub-label {
  position: relative;
  font-size: 1.7rem;
  margin-bottom: 1rem;
}
@media (max-width: 575.98px) {
  .cards-lg-content .sub-label {
    font-size: 1.3rem;
  }
}
.cards-lg-content .display {
  font-family: "Fira Sans", sans-serif;
  font-size: 1.3rem;
  margin: 0;
}
.cards-lg-content .display-small {
  font-size: 1.1rem;
}
@media (max-width: 575.98px) {
  .cards-lg-content .display {
    font-size: 1rem;
  }
}
.cards-lg-content .sub-title {
  position: relative;
  font-size: 2rem;
  margin: 0;
}
.cards-lg-content .sub-display {
  font-family: "Fira Sans", sans-serif;
  font-size: 0.95rem;
  margin: 0;
}
.cards-lg-content .content-info {
  margin-top: 2rem;
}
.cards-content {
  margin: 2rem auto;
}
.cards-content .title {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.9rem;
}
.cards-content .title:after {
  position: absolute;
  content: "";
  background-color: #dc3545;
  height: 4px;
  width: 6rem;
  left: 0;
  bottom: 0;
}
.cards-content .embed {
  display: block;
  width: 100%;
}
.cards-content .content-text ul,
.cards-content .content-text ol {
  margin: 0;
  padding: 0;
  padding-left: 1rem;
}
.cards-content .content-text ul li,
.cards-content .content-text ol li {
  margin-bottom: 0.4rem;
}
.cards-content .content-text ul li ol,
.cards-content .content-text ul li ul,
.cards-content .content-text ol li ol,
.cards-content .content-text ol li ul {
  margin-top: 0.4rem;
}
.cards-content .content-text ul li ol li,
.cards-content .content-text ul li ul li,
.cards-content .content-text ol li ol li,
.cards-content .content-text ol li ul li {
  margin-bottom: 0.2rem;
}
.cards-content .content-text .display {
  font-size: 1.5rem;
  font-weight: 300;
}
@media (max-width: 575.98px) {
  .cards-content .content-text .display {
    font-size: 1.1rem;
  }
}
.cards.with-icons {
  display: flex;
  margin: 0.7rem auto;
  overflow: hidden;
  position: relative;
  border-radius: 1.2rem;
}
.cards.with-icons .figure {
  margin: 0;
  padding: 0;
}
.cards.with-icons .banner-svg {
  width: 100%;
  display: block;
  border-radius: 1.2rem;
}
.cards.with-icons:hover,
.cards.with-icons .active {
  border-color: #2F2F9B;
}
.cards.with-icons:hover .card-text,
.cards.with-icons .active .card-text {
  color: #2F2F9B;
}
.cards-figure {
  position: relative;
  margin-bottom: 1.4rem;
}
.cards-figure .banner-svg {
  width: 100%;
  display: block;
  border-radius: 1.2rem;
}
.cards-figure .text-img {
  position: absolute;
  bottom: 1rem;
  left: "Poppins", Helvetica, Arial, sans-serif;
  color: #fff;
  background: #212529;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  padding: 0.2rem 1rem;
  z-index: 2;
  display: block;
}
.cards-blockquote {
  padding-top: 4rem;
}
.cards-blockquote .title-content {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  margin-bottom: 3rem;
}
.cards-blockquote .title-stars {
  margin-bottom: 2rem;
  font-size: 1.8rem;
  color: #fd7e14;
}
@media (max-width: 575.98px) {
  .cards-blockquote .title-content {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
  .cards-blockquote .title-stars {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
}
.cards-mobile-view {
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  max-width: 340px;
  margin: 0 auto;
}
@media (max-width: 575.98px) {
  .cards-mobile-view {
    position: relative;
  }
}
.cards-mobile-view-inner {
  background-color: #212529;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  padding: 0.5rem;
  padding-bottom: 0;
}
.cards-mobile-view-wrapper .backgrounds {
  position: absolute;
  z-index: -3;
}
@media (max-width: 575.98px) {
  .cards-mobile-view-wrapper .backgrounds {
    display: none;
  }
}
.cards-mobile-view-wrapper .backgrounds.dots {
  top: 40%;
  left: -32%;
}
.cards-mobile-view-wrapper .backgrounds.yellow {
  top: 60%;
  right: -20%;
}
.cards-mobile-view .swiper-slide {
  border-top-left-radius: 2.6rem;
  border-top-right-radius: 2.6rem;
  overflow: hidden;
}
.cards-mobile-view-img {
  display: block;
  width: 100%;
  overflow: hidden;
}
.cards-mobile-view-img img,
.cards-mobile-view-img .svg {
  display: block;
  width: 100%;
}
.cards-mobile-view:after {
  content: "";
  width: 0.35rem;
  height: 0.35rem;
  background-color: #212529;
  position: absolute;
  border-radius: 1rem;
  z-index: 2;
  top: 20px;
  left: 35%;
}
.cards-mobile-view:before {
  content: "";
  border-radius: 10px;
  width: 4rem;
  height: 0.35rem;
  background-color: #212529;
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 40%;
}
.cards-mobile-center {
  position: relative;
  z-index: 2;
  bottom: 0;
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
  margin: 0 auto;
}
@media (max-width: 575.98px) {
  .cards-mobile-center {
    margin: 2.2rem 0;
  }
}
.cards-mobile-center-inner {
  background-color: #17174d;
  border-radius: 2.6rem;
  padding: 0.5rem;
}
.cards-mobile-center .mobile-slider-school {
  padding-top: 30px;
  background-color: #fff;
  border-radius: 2.2rem;
  overflow: hidden;
}
.cards-mobile-center-wrapper .backgrounds {
  position: absolute;
  z-index: -3;
}
@media (max-width: 575.98px) {
  .cards-mobile-center-wrapper .backgrounds {
    display: none;
  }
}
.cards-mobile-center-wrapper .backgrounds.dots {
  top: 40%;
  left: -32%;
}
.cards-mobile-center-wrapper .backgrounds.yellow {
  top: 60%;
  right: -20%;
}
.cards-mobile-center-img {
  display: block;
  width: 100%;
  overflow: hidden;
}
.cards-mobile-center-img img,
.cards-mobile-center-img .svg {
  display: block;
  width: 100%;
}
.cards-mobile-center:after {
  content: "";
  width: 0.35rem;
  height: 0.35rem;
  background-color: #212529;
  position: absolute;
  border-radius: 1rem;
  z-index: 2;
  top: 20px;
  left: 35%;
}
.cards-mobile-center:before {
  content: "";
  border-radius: 10px;
  width: 4rem;
  height: 0.35rem;
  background-color: #212529;
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 40%;
}