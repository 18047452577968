.table-pagination {
	// padding: $padding-base-vertical $padding-base-horizontal;
	// background: $;
	// border-top: 1px solid $default-border;
	// background-color: $light_white_color;
	border: 1px solid lighten($primary, 35%);
	padding: $font-size-base * 0.1;
	margin: $font-size-base 0;
	border-radius: $border-radius;
	.pagination {
		margin: 0;
	}
}
.table-symbols {
	font-size: $font-size-base * 0.875;
	margin: 0;
	> :not(:last-child) > :last-child > * {
		border-bottom-color: $table-border-color;
		// background-color: lighten($primary, 45%);
	}
	> tbody > {
		tr {
			&:last-child {
				td {
					border-color: transparent;
				}
			}
		}
	}
	> thead > tr {
		> th {
			border-color: lighten($primary, 20%);
			background-color: lighten($primary, 43%);
		}
	}
	.badge {
		font-size: $font-size-base * 0.75;
	}
}
