.article {
	&-particles {
		position: relative;
		background-color: darken($blue, 40%);
		canvas {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
		// &:after {
		// 	content: '';
		// 	position: absolute;
		// 	background-color: rgba($blue, 70%);
		// 	top: 0;
		// 	left: 0;
		// 	right: 0;
		// 	bottom: 0;
		// 	z-index: 1;
		// }
	}
	&-logo {
		position: relative;
		// z-index: 2;
		max-width: 480px;
		@include media-breakpoint-down(sm) {
			max-width: initial;
		}
		margin: 0 auto;

		.brand {
			&-img {
				max-width: 40%;
				@include media-breakpoint-down(sm) {
					// max-width: 60%;
					max-width: 40%;
				}
				margin: 0 auto;
			}
			&-title {
				font-size: $font-size-base * 1.3;
				margin-top: $font-size-base * 3.5;
				@include media-breakpoint-down(sm) {
					font-size: $font-size-base * 1.2;
				}
				// color: $white;
			}
			&-apps {
				margin: $font-size-base * 1.3 auto;
			}
		}
		.permalink {
			margin-top: $font-size-base * 0.5;
			font-size: $font-size-base * 1.5;
		}
	}
	&-benifits {
		&.parallax-cover {
			@include media-breakpoint-down(sm) {
				background-size: contain;
			}
		}
	}
	&-mobile {
		&-vertical {
			background-color: lighten($primary, 13%);
			margin-top: $font-size-base * 4;
			margin-bottom: $font-size-base * 3;
			border-radius: $font-size-base;

			.cards-lg-content {
				padding: $font-size-base * 4 0;
			}

			@include media-breakpoint-down(sm) {
				margin-top: 0;
				margin-bottom: 0;
				.cards-lg-content {
					padding: $font-size-base * 2 0;
				}
			}
			&-section {
				padding: $font-size-base * 6 0;
				padding-top: $font-size-base * 3.4;
				@include media-breakpoint-down(sm) {
					padding: 0;
				}
			}
			&-full {
				@include media-breakpoint-down(sm) {
					// background-color: lighten($primary, 13%);
					padding: $font-size-base * 2 0;
				}
			}
			// margin-top: $font-size-base * 2;
			// margin-bottom: $font-size-base * 2;
		}
	}
	&-checkbox {
		padding: $input-padding-y $input-padding-x;
		font-family: $input-font-family;
		@include font-size($input-font-size);
		border: $input-border-width solid $input-border-color;
		background-color: $input-bg;
		@include border-radius($input-border-radius, 0);
		@include box-shadow($input-box-shadow);
		@include transition($input-transition);
	}
	&-module {
		margin-top: $font-size-base * 2;
	}
	&-management {
		background-color: lighten($secondary, 37%);
		// border: $input-border-width solid lighten($secondary, 32%);
		// border-radius:
		@include transition($input-transition);
		@include box-shadow($input-box-shadow);
		margin-bottom: $font-size-base * 2;
		padding: $font-size-base * 2 $font-size-base * 1.2;
		@include border-radius($font-size-base * 0.6);
		.icons-card {
			margin-bottom: $font-size-base * 1.5;
		}
		@include media-breakpoint-down(sm) {
			padding: $font-size-base;
			// margin-bottom: $font-size-base;
			text-align: center;
			.icons-card {
				margin: 0 auto;
				margin-bottom: $font-size-base * 1.3;
			}
		}
		.title {
			&-meta {
				margin-bottom: $font-size-base * 0.6;
			}
			&-text {
				font-size: $font-size-base;
				font-weight: 700;
				line-height: 1.3;
				font-family: $font-poppins;
			}
			&-content {
				margin: 0;
				font-family: $font-fira-sans;
				font-size: $font-size-base * 0.9;
				font-weight: 400;
				p {
					@include text-ellipsis();
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					height: $font-size-base * 4;
					margin: 0;
				}
			}
			@include media-breakpoint-down(sm) {
				&-text {
					font-size: $font-size-base * 0.8;
				}
				&-content {
					font-size: $font-size-base * 0.75;
					p {
						// -webkit-line-clamp: 3;
						height: $font-size-base * 3.4;
					}
				}
			}
		}
	}
	&-blogs {
		border-bottom: $input-border-width solid $input-border-color;
		max-width: 540px;
		margin-bottom: $font-size-base * 2.2;
		padding-bottom: $font-size-base * 1.2;
		// &:last-child {
		// 	margin: 0;
		// 	padding: 0;
		// 	border: none;
		// }
		.meta {
			&-tags {
				margin-bottom: $font-size-base * 1.5;
			}
			&-content {
				// color: $gray-800;
				font-size: $font-size-base * 0.95;
				font-family: $font-fira-sans;
			}
		}
	}
	&-blockquote {
		position: relative;
		// max-width: 760px;
		margin: $font-size-base * 1.5 auto;
		padding: $font-size-base * 3 0;

		margin-bottom: 0;
		padding-bottom: 0;
		@include media-breakpoint-down(sm) {
			margin: $font-size-base auto;
			margin-bottom: 0;
			padding-bottom: 0;
		}
		&:after {
			content: '';
			display: block;
			height: 1px;
			position: absolute;
			top: $font-size-base * 2;
			left: $font-size-base * 8;
			right: 0;
			background-color: $gray-300;
		}
		&:before {
			content: open-quote;
			font-family: $font-awesome-free;
			font-weight: 400;
			display: block;
			width: $font-size-base * 4;
			height: $font-size-base * 4;
			border-radius: 40px;
			position: absolute;
			top: 0;
			left: 0;
			color: $primary;
			letter-spacing: -5px;
			font-size: $font-size-base * 6;
			line-height: $font-size-base * 6.3;
			text-align: center;
			box-shadow: 0 1px 7px 2px rgba($gray-500, 0.3);
		}
	}
}

.app-link {
	position: relative;
	z-index: 10;
	margin: 0 $font-size-base * 0.25;
	display: flex;
	img,
	.svg {
		height: $font-size-base * 2.6;
	}
}
