input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

::-webkit-scrollbar {
	width: 2px;
	height: 2px;
}

::-webkit-scrollbar-track {
	background-color: $gray-300;
	border-radius: 2px;
}

::-webkit-scrollbar-thumb {
	background-color: $primary;
	border-radius: 2px;
}

.no-pad {
	padding: 0px !important;
}

.border_radius_10 {
	border-radius: 10px;
}

.image {
	&-full {
		img {
			width: 100%;
			&.round {
				border-radius: $border-radius * 3;
			}
		}
	}
}

.gap_10 {
	height: 10px;
}

.gap_20 {
	height: 20px;
}

.gap_30 {
	height: 30px;
}

.gap_40 {
	height: 40px;

	@media (max-width: 768px) {
		height: 30px;
	}
}

.gap_60 {
	height: 60px;

	@media (max-width: 768px) {
		height: 40px;
	}
}

.gap_80 {
	height: 80px;

	@media (max-width: 768px) {
		height: 50px;
	}
}

.gap_100 {
	height: 100px;

	@media (max-width: 768px) {
		height: 60px;
	}
}

.gap_120 {
	height: 120px;

	@media (max-width: 768px) {
		height: 100px;
	}
}

.gap_50--mob {
	@media (max-width: 768px) {
		height: 50px;
	}
}

.gap_20--mob {
	@media (max-width: 768px) {
		height: 20px;
	}
}

.overflow_hidden {
	overflow: hidden;
}

.btn_w200 {
	min-width: 200px;
}
.bold {
	font-weight: 600 !important;
}

.page-title {
	// margin-bottom: $font-size-base;
	&.with-margin {
		margin-bottom: $font-size-base * 3;
	}
	.title {
		font-size: 125%;
		margin-bottom: $font-size-base;
		@include media-breakpoint-down(sm) {
			margin: 0;
			font-size: 105%;
		}
	}

	.sub-title {
		font-size: 100%;
		font-family: $font-poppins;
		font-weight: 500 !important;
		margin-bottom: $font-size-base;
		margin-top: $font-size-base * 1.5;
	}
	.thankyou-file {
		margin-top: $font-size-base * 3;
		.text {
			font-size: 80%;
			margin-left: $font-size-base * 0.5;
		}
	}
	@include media-breakpoint-up(sm) {
		.title {
			font-size: 200%;
		}
		.sub-title {
			font-size: 150%;
			margin-top: $font-size-base * 2.2;
		}
	}
}

.with-top-radius {
	border-top-left-radius: $border-radius * 8;
	border-top-right-radius: $border-radius * 8;
	position: relative;
	z-index: 4;
}

.section-padding {
	padding: $font-size-base * 3 0; 
	@include media-breakpoint-down(sm) {
		padding: $font-size-base 0;
	}
	// padding-bottom: 0;
	&.with-hero {
		padding-top: 0;
		padding-bottom: 0;
	}
	&.with-blog { 
		padding: $font-size-base $font-size-base * 0.8;
		padding-bottom: 0;
	}
	&.with-single {
		padding: $font-size-base $font-size-base * 0.9;
		padding-bottom: 0;
	}
	&.with-bg {
		padding: $font-size-base * 2 $font-size-base * 0.8;
		padding-bottom: $font-size-base * 0.5; 
	}
	&.with-form {
		padding: $font-size-base * 1.3 $font-size-base * 1.7;
		padding-bottom: $font-size-base * 0.5;
	}
	&.with-login {
		padding: $font-size-base * 2 0;
		padding-bottom: 0;
		z-index: $zindex-modal * 0.4;
	}
}

.full-pages {
	.with-login {
	}
}
