.text-card {
	padding: $font-size-base * 0.8 0;
	.title {
		font-size: $font-size-base * 1.55;
		font-weight: 600;
	}
	.badge-title {
		@include text-social();
		font-size: $font-size-base * 0.85;
		font-weight: 600;
	}
	.sub-title {
		font-size: $font-size-base * 2;
	}
	.lg-title {
		font-size: $font-size-base * 1.4;
		font-weight: 300;
	}
	.mini-title {
		font-size: $font-size-base * 0.9;
		@include text-social();
	}
	.title-email {
		font-size: $font-size-base * 0.9;
		font-family: $font-fira-sans;
		@include text-social();
	}
}

.text-justify {
	text-align: justify;
}
